<template>
    <div class="clipboard-copy-text-small" :class="{ success: copiedPlaceholderVisible }" @click="copyToClipboard">
        <h2 v-if="copiedPlaceholderVisible">{{ $t('ui.common.copied') }}</h2>
        <h2 v-else>{{ text }}</h2>
        <template v-if="isClipboardSupported && !copiedPlaceholderVisible">
            <SvgIcon icon-id="icon-copy" class="copy-icon icon-size-medium" />
        </template>
    </div>
</template>

<script>
import { deviceType } from '@/modules/core';
import { NOTIFICATION_DURATION } from '@/const/notification';
import { isSupportedCopyIntoClipboard } from '@/modules/core/clipboard/const';
import { copyIntoClipboardDelay } from '@/modules/core/clipboard/copyIntoClipboardDelay';

export default {
    name: 'ClipboardCopyTextSmall',
    props: {
        text: {
            required: true,
            type: String,
        },
        gtmQuery: {
            type: Object,
        },
    },
    data() {
        return {
            isClipboardSupported: !deviceType.isPresto() && isSupportedCopyIntoClipboard,
            copiedPlaceholderVisible: false,
        };
    },
    methods: {
        copyToClipboard() {
            if (!this.isClipboardSupported) {
                return;
            }
            if (this.gtmQuery) {
                this.$gtm.query(this.gtmQuery);
            }
            copyIntoClipboardDelay(this.text, NOTIFICATION_DURATION, () => {
                this.copiedPlaceholderVisible = false;
            });

            this.copiedPlaceholderVisible = true;
        },
    },
};
</script>

<style lang="scss" scoped>
.clipboard-copy-text-small {
    padding: 5px 16px;
    display: inline-flex;
    align-items: center;
    font-size: 22px;
    background: $light-grey-bg;
    cursor: pointer;

    h2 {
        margin-bottom: 0;
        display: inline-block;
    }

    &.success {
        background: $message-success;
    }

    .copy-icon {
        margin-left: 8px;
        fill: $dark-icon-fill;
    }
}
</style>
