<template>
    <div class="page-wrapper">
        <LoadingElement class="loading-element" />
    </div>
</template>

<script>
import { routePath } from '@/router/const-path';
import { action as betslipAction } from '@/modules/sport/store/modules/betslip/const';
import LoadingElement from '@/components/Fragments/LoadingElement.vue';
import { action } from '@/store/const';
import { tryParseNumber } from '@/modules/core/utils/number/tryParseNumber';
export default {
    name: 'ExternalPrefillPage',
    components: { LoadingElement },
    created() {
        this.extractDataAndRedirect();
    },
    methods: {
        prefillPawa6IdsAndRedirect(ids) {
            ids = ids
                .split(',')
                .map((v) => tryParseNumber(v))
                .filter((v) => !!v);
            this.$store.dispatch(betslipAction.LOAD_EXTERNAL_PREFILL_IDS, ids).finally(() => {
                this.$router.replace(routePath.HOMEPAGE);
                this.$store.dispatch(action.TOGGLE_BETSLIP_STATE);
            });
        },

        extractDataAndRedirect() {
            const { selectionIds } = this.$route.query;
            if (selectionIds) {
                this.prefillPawa6IdsAndRedirect(selectionIds);
            } else {
                this.$router.replace(routePath.HOMEPAGE);
            }
        },
    },
};
</script>

<style lang="scss" scoped>
.page-wrapper {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;
    .loading-element {
        width: 70%;
    }
}
</style>
