<template>
    <ProgressiveJpProvider :type="data.content.type">
        <template #default="{ progressiveJackpot }">
            <ProgressiveJpContent v-bind="progressiveJackpot" :mode="data.content.mode" class="progressive-jackpot-banner" />
        </template>
    </ProgressiveJpProvider>
</template>
<script>
import ProgressiveJpContent from '@/components/Fragments/ProgressiveJackpot/ProgressiveJpContent.vue';
import ProgressiveJpProvider from '@/modules/platform/components/ProgressiveJpProvider.vue';

export default {
    components: { ProgressiveJpProvider, ProgressiveJpContent },
    props: ['data'],
};
</script>

<style lang="scss" scoped>
.progressive-jackpot-banner {
    margin: 8px 0;
}
</style>
