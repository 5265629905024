<template>
    <div class="mobile-toolbar">
        <div class="toolbar">
            <div class="topbar-nav-left">
                <div class="table">
                    <a class="sport-menu row-cell align-middle link" :class="{ 'router-link-active': sidebarOpen }" @click="openMenu">
                        <SvgIcon icon-id="icon-hamburger" :icon-fallback="'icons/icon-hamburger'" class="menu-icon" />
                        <template v-if="!$mq.isBeforeSmallest">{{ $t('ui.common.sport') }}</template>
                    </a>
                    <LinkWrapper
                        v-for="link in headerMenuLinks"
                        :key="link.id"
                        :link="link.url"
                        :replace="link.replace"
                        :target="link.target"
                        no-hover
                        class="row-cell align-middle link"
                        @click.native="trackTopNav(link.name)"
                    >
                        {{ link.name }}
                    </LinkWrapper>
                </div>
            </div>
            <div class="topbar-nav-right">
                <TopMenu>
                    <SvgIcon
                        icon-id="icon-search"
                        :class="['icon-search', 'test', { 'search-open': mobileSearchOpen }]"
                        @click.native="toggleMobileSearch()"
                    />
                </TopMenu>
            </div>
        </div>
    </div>
</template>

<script>
import { mapState, mapGetters } from 'vuex';
import { action } from '@/store/const';
import { deviceType, helper } from '@/modules/core';
import { getter as generalGetter } from '@/modules/platform/store/const';
import { getter as authGetter } from '@/modules/platform/store/modules/auth/const';
import { getter as translationsGetter } from '@/store/modules/translations/const';
import { LinkWrapper } from '@/modules/platform/components';

const TopMenu = () => import('./TopMenu');

const LINK_LIST = [
    {
        name: 'sports',
        translation: 'menus.upcoming',
        replace: deviceType.isPresto(),
        to: { name: 'Upcoming' },
        enabler: ['sportsbook'],
    },
    {
        name: 'virtual',
        translation: 'ui.virtualSports.virtualTitle',
        replace: deviceType.isPresto(),
        to: { name: 'Virtual Sports' },
        enabler: ['virtuals.enabled'],
    },
    {
        name: 'jackpot',
        translation: 'ui.jackpot.jackpotTitle',
        to: { name: 'JackpotPage' },
        enabler: ['pool.availableCount'],
    },
];

export default {
    name: 'MobileToolbar',
    components: { TopMenu, LinkWrapper },
    data() {
        return {
            isPresto: deviceType.isPresto(),
        };
    },
    computed: {
        ...mapState({
            sidebarOpen: (state) => state.ui.sidebarOpen,
            mobileSearchOpen: (state) => state.ui.mobileSearchOpen,
            settings: (state) => state.platform.settings,
            prestoMenuConfig: (state) => state.platform.config.prestoMenu,
            legacyMenuLinks(state) {
                if (!state.platform.config.headerMenu?.isHeaderLinksEnabled) return [];
                const itemsPerSize = {
                    mtVerySmall: 0,
                    mtSmall: 1,
                    mtMedium: 2,
                    mtLarge: 3,
                };
                const sizes = Object.keys(itemsPerSize);
                const currentSize = this.$mq.all.filter((mq) => sizes.includes(mq)).pop();

                const transformedLinks = LINK_LIST.map((link) => {
                    return {
                        ...link,
                        url: this.$router.resolve(link.to).href,
                    };
                });
                return transformedLinks
                    .filter((link) => helper.pathChecker(link.enabler, this.settings))
                    .slice(0, itemsPerSize[currentSize]);
            },
        }),
        ...mapGetters({
            isVirtualEnabled: generalGetter.IS_VIRTUAL_ENABLED,
            casinoName: translationsGetter.CASINO_NAME,
            isAuthenticated: authGetter.IS_AUTHENTICATED,
            currentUserStatus: generalGetter.GET_CURRENT_USER_STATUS,
        }),
        strapiMenuLinks() {
            const isEnabled = helper.getObjectField(this.prestoMenuConfig, 'isMenuEnabled', false);
            const links = helper.getObjectField(this.prestoMenuConfig, 'links', []);

            if (!isEnabled || links.length === 0) {
                return [];
            }

            const breakpoints = helper.getObjectField(this.prestoMenuConfig, 'breakpoints', {});
            const numberOfLinksToShow = breakpoints[this.$mq.size] ?? 10;

            return helper
                .processStrapiMenuLinks({
                    links,
                    isAuthenticated: this.isAuthenticated,
                    userStatus: this.currentUserStatus,
                    transformer: (link) => ({ ...link, url: link.externalUrl || link.to || link.fullPath }),
                    router: this.$router,
                })
                .slice(0, numberOfLinksToShow);
        },
        headerMenuLinks() {
            return this.strapiMenuLinks.length > 0 ? this.strapiMenuLinks : this.legacyMenuLinks;
        },
    },
    methods: {
        openMenu() {
            this.$store.commit('closeMobileSearch');
            this.$store.dispatch(action.TOGGLE_SIDEBAR);
            this.trackTopNav('sport');
        },
        toggleMobileSearch() {
            this.$store.commit('toggleMobileSearch');
            this.trackTopNav('toggle-search');
        },
        trackTopNav(element) {
            if (element) {
                this.$gtm.query({
                    event: 'top_nav',
                    click_text: element,
                });
            }
        },
    },
};
</script>

<style scoped lang="scss">
.mobile-toolbar {
    position: relative;
    z-index: 1001;
    flex-shrink: 0; // fix for older versions of chrome

    @include ios_le_12 {
        &.right-sidebar-opened {
            display: none;
        }
    }

    .toolbar {
        border-bottom: $header-border;
        background: $header-mobile-background-color;
        position: relative;
        width: 100%;
        display: table;

        @include all_but_mini {
            display: flex;
            justify-content: space-between;
            align-items: center;
            flex: 0 0 auto;
        }
    }
}

.topbar-nav-left {
    display: table-cell;
    vertical-align: middle;

    .table {
        width: auto;
    }

    img.menu-icon {
        margin-bottom: -3px;
    }

    svg.menu-icon,
    img.menu-icon {
        width: 16px;
        height: 16px;
        margin-right: 3px;
        fill: white;
    }

    .link {
        color: white;
        &.router-link-active {
            color: $header-active-item-color;

            svg.menu-icon,
            span {
                color: $header-active-item-color;
                fill: $header-active-item-color;
            }
        }
        &:hover:not(.router-link-active) {
            color: white;
        }
    }

    .link {
        font-size: 11px;
        font-weight: bold;
        padding: 0 8px;
        text-transform: uppercase;
        cursor: pointer;

        @include maxmain {
            padding-right: 0;
        }
    }
}

.topbar-nav-right {
    float: right;
    display: table-cell;
    vertical-align: middle;

    @include all_but_mini {
        display: flex;
        align-items: center;
    }

    .icon-search {
        width: 17px;
        height: 17px;
        fill: $white-text;
        margin: 0 0.4rem;

        &.search-open {
            fill: $header-active-item-color;
        }
    }
}

.logo {
    margin: 0.6rem 0 0.6rem 0;
    border: 0;
    display: block;

    &.svg-icon {
        width: 150px;
        min-width: 150px;
        height: 24px;
    }
}
</style>
