<template>
    <div class="deposit-title-wrapper">
        <h3>
            <span>{{ title }}</span>
        </h3>
        <Signal class="deposit-title-icon" hide-text :status="selectedProvider.status" />
    </div>
</template>
<script>
import { mapGetters } from 'vuex';
import { getter } from '@/modules/payment/store/const';
import Signal from '@/components/Signal.vue';

export default {
    name: 'DepositTitle',
    components: { Signal },
    props: ['data'],
    computed: {
        ...mapGetters({
            selectedProvider: getter.GET_SELECTED_PROVIDER,
        }),
        title() {
            return this.data.content.title;
        },
    },
};
</script>
<style scoped lang="scss">
.deposit-title {
    &-wrapper {
        display: flex;
        flex-direction: row;
        margin: 16px 0 8px;

        h3 {
            font-size: 18px;
            text-align: left;
            margin: 0;
        }
    }
    &-icon {
        margin-left: 8px;
    }
}
</style>
