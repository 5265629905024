export const action = {
    WATCHER: 'core/watcher',
    LOADER: 'core/loader',
};

export const mutation = {
    START_LOAD: 'core/startLoad',
    END_LOAD: 'core/endLoad',
};

export const getter = {
    IS_LOADING: 'core/isLoading',
    ARE_LOADING: 'core/areLoading',
    ANY_LOADING: 'core/anyLoading',
};
