<template>
    <div>
        <DepositList
            class="how-to-deposit"
            :title="$t('ui.payment.deposit.howToDeposit')"
            :text="$t('ui.payment.deposit.weOfferFollowingOptions')"
        />
    </div>
</template>

<script>
import { DepositList } from '@/modules/payment/components';

export default {
    name: 'Howtodeposit',
    components: { DepositList },
};
</script>

<style scoped lang="scss">
.how-to-deposit {
    // Move to DepositList with how-to-deposit prop
    border-bottom: solid $betpawa-black 2px;
}
</style>
