<template>
    <ErrorPage
        icon="icon-alert"
        track-error="incompatible_device_page_reached"
        :title="$t('ui.casinoLobby.gamesCantLoad')"
        :section-list="[$t('ui.casinoLobby.deviceIsIncompatible')]"
        :button="{ text: $t('ui.common.returnToHome'), emit: true }"
        @button:click="returnToHome()"
    />
</template>
<script>
import ErrorPage from '@/components/Pages/ErrorPage.vue';
import { routeName } from '@/router/const-name';
export default {
    components: { ErrorPage },
    methods: {
        returnToHome() {
            this.$router.push({ name: routeName.HOMEPAGE });
        },
    },
};
</script>
