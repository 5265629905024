<template>
    <div>
        <form class="my-account-page" @submit.prevent>
            <h2 class="title">{{ $t(`ui.common.${pinOrPassword}.changePassword`) }}</h2>
            <span class="sub-header">{{ $t(`ui.common.${pinOrPassword}.changePasswordText`) }}</span>
            <renderer v-if="!inProgress && error" :input="error" class="change-password-error notify error" />
            <template>
                <Password
                    name="currentPassword"
                    :form-name="formNameId"
                    :value="form.currentPassword"
                    :v="$v.form.currentPassword"
                    :label="$t(`ui.common.${pinOrPassword}.currentPassword`)"
                    @value="form.currentPassword = $event"
                />
                <Password
                    name="newPassword"
                    :form-name="formNameId"
                    :value="form.newPassword"
                    :v="$v.form.newPassword"
                    :error-messages="errorMessages"
                    :label="$t(`ui.common.${pinOrPassword}.newPassword`)"
                    @value="form.newPassword = $event"
                />
                <Password
                    name="confirmNewPassword"
                    :form-name="formNameId"
                    :value="form.confirmNewPassword"
                    :v="$v.form.confirmNewPassword"
                    :error-messages="errorMessages"
                    :label="$t(`ui.common.${pinOrPassword}.confirmNewPassword`)"
                    @value="form.confirmNewPassword = $event"
                />
                <input
                    type="submit"
                    :value="$t(`ui.common.${pinOrPassword}.changePassword`)"
                    :disabled="inProgress || (!isPresto && $v.$invalid)"
                    data-test-id="changePassword"
                    class="button button-primary button-full"
                    @click="changePassword()"
                />
            </template>
        </form>
    </div>
</template>

<script>
import { mapState, mapGetters } from 'vuex';
import { required, minLength, numeric, sameAs, not } from 'vuelidate/lib/validators';

import { getter as platformGetter } from '@/modules/platform/store/const';
import { action as authAction } from '@/modules/platform/store/modules/auth/const';

import { deviceType } from '@/modules/core';
import { getter as coreGetter } from '@/modules/core/store/const';

import { getter } from '@/store/modules/translations/const';
import PageMixin from '@/components/Pages/Page.mixin';
import SEOMixin from '@/components/Pages/SEO.mixin';
import Password from '@/components/Password.vue';
import { routeName } from '@/router/const-name';

export default {
    name: 'ChangePassword',
    components: { Password },
    mixins: [PageMixin, SEOMixin],
    data() {
        return {
            form: {
                currentPassword: '',
                newPassword: '',
                confirmNewPassword: '',
            },
            formNameId: 'my-account-form',
            isPresto: deviceType.isPresto(),
        };
    },
    validations() {
        const isPin = this.brandPreference.pin;
        return {
            form: {
                currentPassword: {
                    required,
                    minLength: minLength(4),
                    ...(isPin && { numeric }),
                },
                newPassword: {
                    required,
                    minLength: minLength(4),
                    ...(isPin && { numeric }),
                    newPassword: not(sameAs('currentPassword')),
                },
                confirmNewPassword: {
                    required,
                    minLength: minLength(4),
                    ...(isPin && { numeric }),
                    confirmNewPassword: sameAs('newPassword'),
                },
            },
        };
    },
    computed: {
        ...mapState({
            error: (state) => state.platform.auth.error,
        }),
        ...mapGetters({
            pinOrPassword: getter.PIN_OR_PASSWORD,
            isLoading: coreGetter.IS_LOADING,
            brandPreference: platformGetter.GET_BRAND_PREFERENCE,
        }),
        inProgress() {
            return this.isLoading(authAction.CHANGE_PASSWORD);
        },
        errorMessages() {
            return {
                newPassword: this.$t(`ui.common.${this.pinOrPassword}.equalPasswords`),
                confirmNewPassword: this.$t(`ui.common.${this.pinOrPassword}.wrongConfirmPassword`),
            };
        },
    },
    watch: {
        error(err) {
            if (err) {
                this.$scroll.scrollTo(0, 1);
            }
        },
    },
    beforeDestroy() {
        this.$store.dispatch(authAction.RESET_ERROR);
    },
    methods: {
        changePassword() {
            if (this.isPresto && this.$v.$invalid) {
                this.$v.$touch();
                return;
            }
            this.$store.dispatch(authAction.RESET_ERROR);
            this.$store
                .dispatch(authAction.CHANGE_PASSWORD, {
                    currentPassword: this.form.currentPassword,
                    newPassword: this.form.newPassword,
                })
                .then(() => {
                    this.$router.push({
                        name: routeName.LOGOUT,
                        query: { nextPath: routeName.LOGIN },
                    });
                });
        },
    },
};
</script>

<style scoped lang="scss">
.my-account-page {
    display: flex;
    flex-flow: column nowrap;
    text-align: left;
    padding: 0 20px;
    margin: 10px 0;
}

.button {
    margin-top: 8px;
}

.info {
    text-align: right;
}

.indicator-green,
.indicator-green-blank {
    cursor: pointer;
}
</style>
