<template>
    <div class="chips">
        <h2 class="chips-title">{{ $t('ui.chips.topUp.title') }}</h2>
        <div class="chips-sub-title">{{ $t('ui.chips.topUp.subTitle') }}</div>
        <form class="form" @submit.prevent="handleSubmit">
            <div v-if="boxErrorMessage" class="notify error">
                <renderer :input="boxErrorMessage" />
            </div>
            <div class="chips-content">
                <InputField
                    name="amount"
                    type="number"
                    class="chips-input"
                    data-test-id="chipsAmountField"
                    :attrs="{ placeholder }"
                    :class="{
                        'chips-input-error': $v.$dirty && $v.$invalid,
                        'chips-input-valid': $v.$dirty && !$v.$invalid,
                    }"
                    :form-name="formNameId"
                    :value="form.amount"
                    :error-messages="errorMessages"
                    :v="$v.form.amount"
                    :help-text="
                        $t('ui.chips.topUp.helpText', {
                            currency: currency.symbol,
                            btcCurrency: chips.currency.symbol,
                        })
                    "
                    can-change
                    @value="setAmount"
                >
                    <div class="currency-symbol">
                        <div class="currency-symbol-text">{{ currency.symbol }}</div>
                        <div class="chips-input-divider" />
                    </div>
                </InputField>
                <div class="chips-details">
                    <div class="chips-item">
                        <div class="chips-item-text">{{ $t('ui.chips.topUp.currentBalance') }}</div>
                        <Currency
                            class="value bold"
                            data-test-id="chipsBalanceAmount"
                            :amount="Number(balance) || 0"
                            :format="chipsCurrencyFormat"
                        />
                    </div>
                    <div class="chips-item">
                        <div class="chips-item-text">{{ $t('ui.chips.topUp.topUpAmount') }}</div>
                        <Currency
                            class="value bold"
                            data-test-id="chipsTopUpAmount"
                            :amount="Number(form.amount) || 0"
                            :format="chipsCurrencyFormat"
                        />
                    </div>
                    <div v-if="isExciseTaxVisible" class="chips-item">
                        <div class="chips-item-text">{{ $t('ui.chips.topUp.exciseTaxAmount') }}</div>
                        <Currency
                            class="value bold"
                            data-test-id="chipsTaxAmount"
                            :amount="taxAmount"
                            :format="chipsCurrencyFormat"
                            :is-loading="isChipsTopUpTaxLoading"
                        >
                            -
                        </Currency>
                    </div>
                    <div class="chips-item">
                        <div class="chips-item-text">{{ $t('ui.chips.topUp.finalBalance') }}</div>
                        <Currency
                            class="value bold"
                            data-test-id="chipsFinalAmount"
                            :amount="finalBalance || 0"
                            :format="chipsCurrencyFormat"
                            :is-loading="isChipsTopUpTaxLoading"
                        />
                    </div>
                </div>
            </div>
            <button
                :disabled="isConfirmButtonDisabled"
                data-test-id="chipsConfirmBtn"
                class="button-confirm button button-full button-primary"
            >
                {{ $t('ui.chips.topUp.confirmButton') }}
            </button>
            <div class="button-cancel" data-test-id="chipsCancelBtn" @click="$modal.hide('chips-modal')">{{ $t('ui.common.cancel') }}</div>
        </form>
    </div>
</template>

<script>
import { mapActions, mapGetters, mapMutations, mapState } from 'vuex';
import { integer, minValue } from 'vuelidate/lib/validators';
import { helper } from '@/modules/core';
import { getter as coreGetter } from '@/modules/core/store/const';
import { getter as platformGetter, action as platformAction } from '@/modules/platform/store/const';
import { getter as authGetter } from '@/modules/platform/store/modules/auth/const';

import { InputField, Currency } from '@/modules/core/components';
import { mutation as generalMutation } from '@/store/const';

const DEFAULT_TAX_DEBOUNCE_TIMOUT = 1000;

const notification = {
    type: 'verify',
    data: {
        id: 'verifySuccess',
        trigger: false,
    },
};

export default {
    name: 'ChipsTopUp',
    components: { Currency, InputField },
    data() {
        return {
            boxErrorMessage: null,
            formNameId: 'chips-input',
            form: {
                amount: null,
            },
            isFetchTopUpTaxDebouncing: false,
            getTopUpTaxPreviewDebounceCallBack: helper.debounce(
                (amount) => this.$store.dispatch(platformAction.GET_CHIPS_TOP_UP_TAX_PREVIEW, amount),
                helper.getObjectField(this.brandPreference, 'topUpChipsTaxDebounceTimeout', null) || DEFAULT_TAX_DEBOUNCE_TIMOUT,
                (isDebouncing) => {
                    this.isFetchTopUpTaxDebouncing = isDebouncing;
                }
            ),
            balance: 0,
        };
    },
    validations() {
        const { amountIsNumberRule } = helper;

        return {
            form: {
                amount: {
                    integer,
                    amountIsNumberRule,
                    minAmountRule: minValue(0),
                },
            },
        };
    },
    computed: {
        ...mapState({
            currency: (state) => state.platform.settings.currency,
            chips: (state) => state.platform.settings.chips,
            error: (state) => state.platform.chips.errorMessage,
        }),
        ...mapGetters({
            chipsBalance: authGetter.GET_CHIPS_BALANCE,
            accountBalance: authGetter.GET_BALANCE,
            preference: platformGetter.GET_PREFERENCE,
            isLoading: coreGetter.IS_LOADING,
            brandPreference: platformGetter.GET_BRAND_PREFERENCE,
            topUpTaxDetails: platformGetter.GET_TOP_UP_TAX_DETAILS,
        }),
        taxAmount() {
            return (this.form.amount && this.topUpTaxDetails.taxAmount) || 0;
        },
        finalBalance() {
            return helper.rounded(Number(this.form.amount) + Number(this.balance) - this.taxAmount, 2);
        },
        isConfirmButtonDisabled() {
            return this.isLoading(platformAction.CHIPS_BALANCE_EXCHANGE) || this.$v.$invalid || this.isChipsTopUpTaxLoading;
        },
        errorMessages() {
            const amount = Math.floor(this.form.amount);
            return {
                integer: this.$t('ui.common.form.error.integer', { down: amount, up: amount + 1 }),
                amountIsNumberRule: this.$t('ui.common.error.invalidAmount'),
            };
        },
        placeholder() {
            return this.$options.filters.currency(0, '%s');
        },
        isExciseTaxVisible() {
            return this.taxAmount > 0;
        },
        isChipsTopUpTaxLoading() {
            return this.isFetchTopUpTaxDebouncing || this.isLoading(platformAction.GET_CHIPS_TOP_UP_TAX_PREVIEW);
        },
        chipsCurrencyFormat() {
            return this.chips.currency.format;
        },
    },
    watch: {
        error: {
            deep: true,
            handler(value) {
                this.boxErrorMessage = value;
            },
        },
    },
    mounted() {
        this.balance = this.chipsBalance;
    },
    methods: {
        ...mapActions({
            chipBalanceExchange: platformAction.CHIPS_BALANCE_EXCHANGE,
        }),
        ...mapMutations({
            clearNotification: generalMutation.CLEAR_NOTIFICATIONS,
        }),
        handleSubmit() {
            this.boxErrorMessage = null;
            if (this.$v.$invalid) {
                this.$v.$touch();
                return;
            }
            if (this.accountBalance < this.form.amount) {
                this.$gtm.query({
                    event: 'chips_topup_low_balance_failed_attempt',
                });
                this.boxErrorMessage = this.$t('ui.chips.validations.makeDeposit');
                return;
            }
            this.clearNotification();
            this.chipBalanceExchange({
                amount: this.form.amount,
                isTopUp: true,
                notificationData: {
                    ...notification,
                    data: {
                        ...notification.data,
                        message: this.$t('ui.chips.topUp.notification', {
                            amount: this.$options.filters.currency(this.form.amount - this.taxAmount, '%s'),
                        }),
                    },
                },
            }).then(() => {
                this.form.amount = null;
                this.$modal.hide('chips-modal');
            });
        },
        setAmount(value) {
            this.form.amount = value;
            if (Number(value)) {
                this.getTopUpTaxPreviewDebounceCallBack(value);
            }
        },
    },
};
</script>

<style lang="scss" scoped>
::v-deep #chips-input-amount {
    border: none;
}

::v-deep .input-field-wrapper {
    border: 1px solid #353b40;
    &:focus-within {
        border-color: #17c7e6;
    }
}

.chips {
    &-input {
        &-error {
            ::v-deep .input-field-wrapper {
                border-color: red;
            }
        }
        &-valid {
            ::v-deep .input-field-wrapper {
                border-color: #9ce800;
            }
        }
        &-divider {
            height: inherit;
            width: 1px;
            background: #e6e7e2;
            margin-left: 8px;
        }

        ::v-deep input::placeholder {
            color: transparentize($disabled-text, 0.6);
        }
    }

    .currency {
        &-symbol {
            display: flex;
            vertical-align: top;
            padding: 8px 0 8px 8px;
            font-size: 14px;
            line-height: 1.1rem;
            color: $disabled-text;
            text-transform: uppercase;
            border-right: none;
            &-text {
                color: $green-success;
            }
        }
    }

    .tax-info {
        margin-top: 5px;
        font-size: 12px;
        color: #8e9398;
        text-align: center;
        font-weight: 325;
    }
}
</style>
