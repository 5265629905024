<template>
    <div
        class="jackpot"
        :class="[isSingle ? 'single' : 'multi', cssClass]"
        :style="{
            backgroundImage: `url(${jackpotMask})`,
            backgroundColor: validateColor(presentationColor) || null,
        }"
    >
        <div class="jackpot-outer-wrapper">
            <span class="jackpot-inner-wrapper">
                <span v-if="!$mq.isSmallest" class="jackpot-date">
                    {{ time }}
                    <br />
                    {{ date }}
                </span>
                <span class="jackpot-prize"> {{ jackpot.maxPrize | currency(currencyFormat, 0) }} {{ taxEnabled ? '*' : '' }} </span>
                <h3>{{ jackpot.name }}</h3>
                <slot name="lucky" />
                <span v-if="$mq.isSmallest" class="jackpot-date jackpot-date-presto">{{ time }} {{ date }}</span>
                <div class="jackpot-summary">
                    <template v-if="isJackpotClosed">
                        <div class="bold">
                            {{ $t('ui.jackpot.ticketPrice') }}: {{ jackpot.fee | currency(currencyFormat, decimalBetPrecision) }}
                        </div>
                        <template v-if="jackpot.isPaid && jackpot.settlementInfo.hasWinners">
                            <div v-if="statistics.winners || statistics.losers">
                                <div v-if="statistics.winners && statistics.winners.count">
                                    {{ $t('ui.jackpot.tiebreakers.winners') }}
                                    <span class="bold">
                                        {{ `${statistics.winners.count}, ` }}
                                        {{ statistics.winners.payout | currency(currencyFormat, decimalBetPrecision) }}
                                    </span>
                                </div>
                                <div v-if="statistics.losers && statistics.losers.count">
                                    {{ $t('ui.jackpot.tiebreakers.losers') }}
                                    <span class="bold">
                                        {{ `${statistics.losers.count}, ` }}
                                        {{ statistics.losers.payout | currency(currencyFormat, decimalBetPrecision) }}
                                    </span>
                                </div>
                            </div>
                            <div v-else>
                                <div v-for="(prize, index) in jackpotPrizes" :key="jackpot.name + '-prize-' + index">
                                    {{ jackpot.rowCount - prize.mistakes + ' ' + $t('ui.jackpot.correct') + ':' }}
                                    <span v-if="prize.wonPerTicket" class="bold">
                                        {{ prize.wonTickets + ' ' + $t(`ui.jackpot.winner${prize.wonTickets > 1 ? 's' : ''}`) + ', ' }}
                                        {{ prize.wonPerTicket | currency(currencyFormat, 0) }}
                                        {{ taxEnabled ? '*' : '' }}
                                    </span>
                                    <span v-else class="bold">{{ $t('ui.jackpot.none') }}</span>
                                </div>
                                <h4 v-if="hasSlot && taxEnabled">{{ $t('ui.jackpot.taxEnabledMessage', { taxAmount }) }}</h4>
                            </div>
                        </template>
                        <div v-else-if="jackpot.isPaid && !jackpot.settlementInfo.hasWinners">{{ $t('ui.jackpot.noWinners') }}</div>
                        <div v-else>{{ $t('ui.jackpot.ongoing') }}</div>
                    </template>
                    <template v-else>
                        <div class="bold text-mid">
                            {{ $t('ui.jackpot.winDetails', { eventsTotal: jackpot.rowCount, allowedMin: jackpot.minimumCorrectWin }) }}
                        </div>
                        <div v-if="isSingle && jackpot.description && showDescription">{{ jackpot.description }}</div>
                    </template>
                </div>
                <a v-if="!hasSlot && button.text" class="button-accent button button-full" @click="openJackpot(button.event)">
                    {{ button.text }}
                </a>
                <slot name="cta" />
                <div v-if="taxEnabled" :class="`jackpot-tax text-${showDescription ? 'left' : 'mid'}`">
                    {{ $t('ui.jackpot.taxEnabledMessage', { taxAmount }) }}
                </div>
            </span>
        </div>
    </div>
</template>

<script>
import { mapState, mapGetters } from 'vuex';
import { helper } from '@/modules/core';
import { jackpotType, jackpotStatus } from '@/modules/sport';
import { getter } from '@/modules/platform/store/const';

import jackpotMask from '@/assets/images/backgrounds/jp-mask.png';

export default {
    name: 'Jackpot',
    props: {
        jackpot: {
            required: true,
        },
        jackpotStatus: {
            type: String,
            required: false,
        },
        showDescription: {
            type: Boolean,
            default: false,
        },
        button: {
            default: () => ({ text: '', event: '' }),
        },
    },
    data() {
        return {
            jackpotMask,
        };
    },
    computed: {
        ...mapState({
            decimalBetPrecision: (state) => state.platform.settings.betting.real.precision,
            currencyFormat: (state) => state.platform.settings.currency.format,
        }),
        ...mapGetters({
            dateOptions: getter.GET_DATE_OPTIONS, // Implement global date time BP-17850
            taxEnabled: getter.IS_POOL_TAX_ENABLED,
            taxPoolAmount: getter.GET_POOL_TAX_AMOUNT,
        }),
        taxAmount() {
            return this.taxPoolAmount * 100;
        },
        hasSlot() {
            return !!this.$slots.default;
        },
        // TODO: get rid of strings comparison by requesting a proper response format from platform
        isJackpotClosed() {
            return (
                (this.jackpot.status && this.jackpot.status === jackpotStatus.CLOSED) ||
                (this.jackpotStatus && this.jackpotStatus === 'closed')
            );
        },
        closeTime() {
            return this.$formatDateTime(this.jackpot.closeTime, { toObject: true, isUtc: true, ...this.dateOptions });
        },
        time() {
            const { time } = this.closeTime;
            return time;
        },
        date() {
            const { day, date, month } = this.closeTime;
            return `${day} ${date}/${month}`;
        },
        presentationColor() {
            const [color] = (this.jackpot.presentation || '').split(' ');
            return color;
        },
        cssClass() {
            return !this.validateColor(this.presentationColor) && this.presentationColor;
        },
        statistics() {
            return this.jackpot?.tiebreakers?.statistics || {};
        },
        isSingle() {
            return [jackpotType.SINGLE, jackpotType.MULTIJURISDICTION].includes(this.jackpot.type);
        },
        jackpotPrizes() {
            const result = {};
            for (const prize of this.jackpot?.prizes || []) {
                result[prize.mistakes] = {
                    ...prize,
                    wonTickets: (result[prize.mistakes]?.wonTickets || 0) + (prize?.wonTickets || 0),
                };
            }
            return Object.values(result);
        },
    },
    methods: {
        $i: helper.interpolate,
        $formatDateTime: helper.formatDateTime,
        openJackpot(event) {
            this.$emit(event || 'open', this.jackpot);
        },
        validateColor(color) {
            document.head.style.color = color;
            const result = document.head.style.color;
            document.head.style.color = null;
            return !!result && result;
        },
    },
};
</script>

<style scoped lang="scss">
.jackpot {
    border: 1px solid $jackpot-banner-border-color;
    background: no-repeat left top;
    background-size: $jackpot-banner-bg-size;

    &.single {
        background-color: $jackpot-banner-color-single;
    }

    &.multi {
        background-color: $jackpot-banner-color-multi;
    }

    &.mondo.single,
    &.mondo.multi {
        background-color: $jackpot-banner-color-mondo;
    }

    a.button {
        margin-top: 0;
    }

    &-tax {
        margin-top: 10px;
        font-size: 10px;
    }

    &-date {
        @extend %body-small-font-700;
        display: block;
        text-align: right;
        float: right;
        margin: 0 0 10px 20px;

        &-presto {
            display: block;
            text-align: left;
            float: none;
            margin: 0 0 8px 0;
        }
    }

    &-prize {
        @extend %h2-font-700;
        color: $jackpot-banner-title-color;

        @include oldschool {
            font-size: 18px;
            line-height: 18px;
        }

        + h3 {
            @extend %body-big-font-700;
            color: $jackpot-banner-title-color;
        }
    }

    &-summary {
        @extend %body-small-font-400;
        + a.button {
            margin-top: 10px;
        }

        > div:not(:last-child) {
            margin-bottom: 10px;
        }
    }

    h3 {
        margin: 0px 0px 12px;

        @include oldschool {
            font-size: 16px;
            padding: 4px 0;
            margin-bottom: 4px;
        }
    }

    h4 {
        padding-top: 4px;
        font-size: 13px;
        font-weight: 400;
        margin: 0px;
        @include oldschool {
            margin-bottom: 4px;
        }

        & .bold {
            min-width: 100px;
            display: inline-block;
            @include oldschool {
                display: block;
                margin-top: 4px;
            }
        }
    }

    .lucky-dip {
        display: inline-table;
        float: right;
        margin: 0 0 10px 20px;
        line-height: 22px;
    }

    &-outer-wrapper {
        margin: 0 auto;
        display: table;
        width: 100%;
        table-layout: fixed;
        max-width: 700px;
    }

    &-inner-wrapper {
        word-break: break-word;
        padding: 12px;
        display: table-cell;
        color: $jackpot-color;
        text-decoration: none;
    }
}
</style>
