<template>
    <button class="button" :class="getClass" :disabled="disabled">
        <span v-if="$slots.loader && loading && !isPresto" class="button-loader">
            <slot name="loader" />
        </span>
        <template v-else>
            <span v-if="$slots.left" class="button-media">
                <slot name="left" />
            </span>
            <span v-if="$slots.center" class="button-text">
                <slot name="center" />
            </span>
            <span v-if="$slots.right" class="button-media">
                <slot name="right" />
            </span>
        </template>
    </button>
</template>

<script>
import { deviceType } from '@/modules/core';

export default {
    name: 'SimpleButton',
    props: {
        color: {
            type: String,
            default: 'accent',
        },
        fullSize: {
            type: Boolean,
            default: false,
        },
        disabled: {
            type: Boolean,
            default: false,
        },
        loading: {
            type: Boolean,
            default: false,
        },
    },
    data() {
        return {
            isPresto: deviceType.isPresto(),
        };
    },
    computed: {
        getClass() {
            let classList = '';
            classList += `button-${this.color} `;
            classList += this.fullSize ? `button-full ` : '';
            return classList;
        },
    },
};
</script>

<style scoped lang="scss">
.button {
    text-align: center;

    &-text {
        margin: 0 5px;
    }
}
.button-loader {
    width: 16px;
    height: 16px;
    display: block;
}
</style>
