<template>
    <div v-if="versionName" class="version-app">{{ $t('android.appVersion') }} {{ versionName }}</div>
</template>

<script>
import { android } from '@/modules/core';

export default {
    name: 'VersionApp',
    computed: {
        versionName() {
            return android.state.versionName;
        },
    },
};
</script>
<style lang="scss" scoped>
.version-app {
    text-align: center;
    font-style: italic;
    padding: 8px;
    text-transform: none;
    font-size: 12px;

    &.version-app-topmenu {
        color: $grey-text;
        background: $dark-grey;
    }

    &.version-app-footer {
        position: relative;
        margin-top: -1px;
        min-height: 1rem;
        box-sizing: content-box;
        color: $white-text;
        background: $footer-background;
    }
}
</style>
