import Vue from 'vue';

import { helper } from '@/modules/core';
import { getObjectField } from '@/modules/core/utils/helper';

import { getter as platformGetter } from '@/modules/platform/store/const';
import { defaultCasinoPath } from '@/modules/platform/const/casino-const';

import { getLocalTypes } from '@/store/utils';
import { action, getter, mutation } from '@/store/modules/translations/const';

const { VUE_APP_DEFAULT_LANGUAGE } = process.env;
const getSearchParamByName = (name) => {
    const params = new URLSearchParams(window.location.search.substring(1));
    return params.get(name);
};

const CONFIGURATION_STATE = {
    locale: VUE_APP_DEFAULT_LANGUAGE,
    extraLocales: [],
};

const state = {
    language: null,
    configuration: { ...CONFIGURATION_STATE },
    persist: null,
    locales: {},
    languageList: [],
};

const _getter = getLocalTypes(getter);

const getters = {
    [_getter.PIN_OR_PASSWORD]: (state, getters, rootState, rootGetters) => {
        const { pin } = rootGetters[platformGetter.GET_BRAND_PREFERENCE];
        return pin ? 'pin' : 'password';
    },
    [_getter.CASINO_NAME]: (state, getters, rootState, rootGetters) => {
        const brandPreference = rootGetters[platformGetter.GET_BRAND_PREFERENCE];
        const path = helper.getObjectField(brandPreference, 'casinoType.path') || defaultCasinoPath;
        return Vue.$t(`ui.casinoLobby.${path}`);
    },
    [_getter.LANGUAGE]: ({ language, locales, persist, languageList }) => {
        return { language, locales, persist, languageList };
    },
    [_getter.GET_CONFIGURATION]: ({ configuration }) => {
        return configuration;
    },
    [_getter.GET_SELECTED_LANGUAGE]: ({ language }) => {
        return language;
    },
    [_getter.GET_SELECTED_LANGUAGE_OR_DEFAULT]: ({ language }) => {
        if (language) return language;
        const forcedLanguage = getSearchParamByName('language');
        const deviceLang = navigator.language.split('-')[0];
        return forcedLanguage || deviceLang;
    },
    [_getter.LANGUAGE_LIST]: ({ locales, language, languageList }) => {
        const { otherLanguages } = locales[language] || {};
        if (!otherLanguages) return [];
        return languageList.map((key) => {
            const { languageSwitcher } = otherLanguages[key];
            return {
                key,
                text: languageSwitcher || key,
                isSelected: key === language,
            };
        });
    },
};

const _action = getLocalTypes(action);

const actions = {
    [_action.SWITCH_LANGUAGE]({ commit }, targetLanguage) {
        commit(_mutation.SET_LANGUAGE, targetLanguage);
        Vue.nextTick(() => {
            window.location.reload();
        });
    },
    [_action.GET_CRITICAL_TRANSLATIONS](_, lang) {
        return import(`@/project/common/localization/${lang}/translations.critical.json`)
            .then((module) => getObjectField(module, 'default', module))
            .catch((e) => {
                console.error(e);
            });
    },
    [_action.GET_TRANSLATIONS]({ commit, getters, dispatch }) {
        const { locale, extraLocales = [] } = getters[_getter.GET_CONFIGURATION];
        const selectedLanguage = getters[_getter.GET_SELECTED_LANGUAGE_OR_DEFAULT];
        const locales = [locale, ...extraLocales];
        const lang = locales.includes(selectedLanguage) ? selectedLanguage : locale;
        return new Promise((resolve, reject) => {
            const localeKey = `VUE_APP_${lang.toUpperCase()}_LOCALE`;
            Vue.$http
                .get(`${window.location.origin}/${process.env[localeKey]}`)
                .then(({ data }) => {
                    return dispatch(_action.GET_CRITICAL_TRANSLATIONS, lang.toLowerCase()).then((critical) => {
                        data.critical = critical;
                        return data;
                    });
                })
                .then((data) => {
                    /**
                     * Set user locale to prime translation plugin $t()
                     */
                    commit(_mutation.SET_LOCALE, {
                        locale: data,
                        language: lang,
                        languageList: locales,
                    });
                    resolve();
                })
                .catch((error) => {
                    /**
                     * Set user locale and critical translations to prime translation plugin $t()
                     */
                    dispatch(_action.GET_CRITICAL_TRANSLATIONS, lang.toLowerCase()).then((critical) => {
                        commit(_mutation.SET_LOCALE, {
                            locale: { critical, errors: { global: {} } },
                            language: lang,
                            languageList: locales,
                        });
                        reject(error);
                    });
                    Vue.$sentry.withScope((scope) => {
                        scope.setExtras({ error });
                        scope.setTag('statusCode', error.statusCode);
                        scope.setTag('scope', 'staticTranslationFiles');
                        scope.setTag('staticTranslationFiles', 'unavailable');
                        scope.setLevel('fatal');
                        Vue.$sentry.captureMessage('FATAL_ERROR_STATIC_TRANSLATIONS');
                    });
                });
        });
    },
};

const _mutation = getLocalTypes(mutation);

const mutations = {
    [_mutation.SET_LANGUAGE](state, value) {
        state.language = value;
        state.persist = true;
    },
    [_mutation.SET_CONFIGURATION](state, { locale, extraLocales }) {
        state.configuration = {
            locale: locale || CONFIGURATION_STATE.locale,
            extraLocales: Array.isArray(extraLocales) ? extraLocales : CONFIGURATION_STATE.extraLocales,
        };
    },
    [_mutation.SET_LOCALE](state, { locale, language, languageList }) {
        state.locales = { [language]: locale };
        state.languageList = languageList || [language];
    },
};

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations,
};
