<template>
    <section v-if="isActive" :aria-hidden="!isActive" class="tab-section">
        <slot />
    </section>
</template>

<script>
import { helper } from '@/modules/core';

export default {
    props: {
        name: { required: true },
    },
    computed: {
        isActive() {
            return helper.getObjectField(this.$parent.$props, 'active.key') === this.name;
        },
    },
};
</script>
