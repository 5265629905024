<template>
    <div v-if="languages.length > 1" class="language-switch menu-item" :class="{ horizontal, dark }">
        <span
            v-for="lang of languages"
            :key="lang.key"
            :data-test-id="`language-${lang.key}`"
            :class="{ selected: lang.isSelected }"
            class="button"
            @click="switchLang(lang.key)"
        >
            <div class="language-content">
                <Radiobutton :id="`language-${parent}-${lang.key}`" :theme="getTheme" :checked="lang.isSelected" />
                <div class="icon-language-flag">
                    <SvgIcon class="icon-size-very-large" :icon-id="`icon-language-flag-${lang.key}`" />
                </div>
                {{ lang.text }}
            </div>
        </span>
    </div>
</template>

<script>
import { mapGetters } from 'vuex';
import { action as platformAction } from '@/modules/platform/store/const';
import { getter as authGetter } from '@/modules/platform/store/modules/auth/const';
import { getter as coreGetter } from '@/modules/core/store/const';

import Radiobutton from '@/components/Radiobutton.vue';

export default {
    name: 'LanguageSelector',
    components: { Radiobutton },
    props: {
        horizontal: {
            type: Boolean,
            default: true,
        },
        dark: {
            type: Boolean,
            default: false,
        },
        parent: {
            type: String,
        },
    },
    computed: {
        ...mapGetters({
            languages: 'translations/getLanguageList',
            isAuthenticated: authGetter.IS_AUTHENTICATED,
            isLoading: coreGetter.IS_LOADING,
        }),
        getTheme() {
            return this.dark ? 'dark' : 'light';
        },
        isLangUpdateInProgress() {
            return this.isLoading(platformAction.UPDATE_LANGUAGE_PREFERENCE);
        },
    },
    methods: {
        switchLang(language) {
            if (this.isLangUpdateInProgress) return;
            this.$store.dispatch(platformAction.CHANGE_SITE_LANGUAGE, { language, component: this.parent });
        },
    },
};
</script>

<style lang="scss" scoped>
.language-label {
    padding-bottom: 0;
}
.language-switch {
    @at-root .dark#{&} {
        .button {
            background: #353b40;
            color: white;

            &.selected {
                background: none;
            }

            svg.icon-circle {
                color: #aaaeb0;
                &-check {
                    color: white;
                }
            }
        }
    }

    .button {
        align-items: center;
        width: 100%;
        text-align: left;
        font-weight: normal;
        padding: 6px 8px;
        text-transform: none;
        border: 1px solid transparent;

        @at-root {
            .menu-contents#{&} {
                width: 100%;
            }

            .horizontal.footer-language#{&} {
                width: 50%;
            }
        }

        &.selected {
            border: 1px solid #e6e7e2;
            background: #f4f5f0;
        }

        svg.icon-circle {
            margin-right: 10px;
            color: transparent;
            fill: currentColor;

            &-check {
                margin-right: 10px;
                color: black;
                fill: currentColor;
            }
        }

        .icon-language-flag {
            margin-right: 10px;
        }

        .language-content {
            display: flex;
            align-items: center;
        }
    }
}
</style>
