<template>
    <div v-if="isAuthenticated" class="no-margins user-phone-operator">
        <UserPhoneOperator :show-verified-status="data.content.isVerifiedIconShown" />
    </div>
</template>

<script>
import { UserPhoneOperator } from '@/modules/payment/components';
import { mapGetters } from 'vuex';
import { getter as authGetter } from '@/modules/platform/store/modules/auth/const';

export default {
    name: 'Userphoneoperator',
    components: { UserPhoneOperator },
    props: {
        data: Object,
    },
    computed: {
        ...mapGetters({
            isAuthenticated: authGetter.IS_AUTHENTICATED,
        }),
    },
};
</script>

<style scoped lang="scss">
.user-phone-operator {
    padding: 17px 20px;
    background: #f4f5f0;
    ::v-deep .user-phone {
        margin: 0;
        background: white;
    }
}
</style>
