<template>
    <span>
        <router-link
            v-if="isAuthenticated"
            :to="{ path: '/deposit' }"
            class="header-buttons header-buttons-authenticated"
            :class="{ compact: isCompact }"
            @click.native="trackTopNav('deposit')"
        >
            <template>
                <Spinner v-if="!isBalanceReady" elevation="1" class="balance-loader" visible :zoom="70" />
                <template v-else-if="isBalanceValid">
                    <span class="button balance">
                        {{ balance | currency(currency.format) }}
                    </span>
                    <span class="button button-primary button-deposit" :class="{ compact: isCompact }">
                        <SvgIcon v-if="isCompact" icon-id="icon-plus" class="icon-deposit" vertical-align="top" />
                        <template v-else>
                            {{ $t('ui.payment.deposit.button') }}
                        </template>
                    </span>
                </template>
                <span v-else id="show-balance-manually" class="button show-balance button-tertiary" @click="showBalance">
                    {{ $t('ui.common.showBalance') }}
                </span>
            </template>
        </router-link>
        <span v-else class="header-buttons">
            <router-link :to="{ name: 'Login' }" class="button button-accent" @click.native="trackTopNav('login')">
                {{ $t('ui.common.login') }}
            </router-link>
            <router-link :to="{ name: 'JoinNow' }" class="button button-primary" @click.native="trackTopNav('join_now')">
                {{ $t('ui.common.joinNow') }}
            </router-link>
        </span>
    </span>
</template>

<script>
import { mapState, mapGetters, mapActions } from 'vuex';
import { getter as authGetter, action as authAction } from '@/modules/platform/store/modules/auth/const';
import { Spinner } from '@/modules/core/components';
import { isNumber } from '@/modules/core/utils/number/isNumber';

export default {
    name: 'HeaderBalance',
    components: { Spinner },
    props: {
        isCompact: Boolean,
    },
    computed: {
        ...mapState({
            currency: (state) => state.platform.settings.currency,
            isBalanceReady: (state) => state.platform.auth.isBalanceReady,
        }),
        ...mapGetters({
            isAuthenticated: authGetter.IS_AUTHENTICATED,
            balance: authGetter.GET_BALANCE,
        }),
        isBalanceValid() {
            return isNumber(this.balance);
        },
    },
    methods: {
        ...mapActions({
            getBalance: authAction.GET_BALANCE,
        }),
        trackTopNav(key) {
            this.$gtm.query({
                event: this.$gtm.makeValidEventName(`header_${key}_click`),
                click_element: 'button',
            });
        },
        showBalance() {
            this.getBalance({ force: true });
        },
    },
};
</script>

<style lang="scss" scoped>
.show-balance {
    margin-right: 7px;
    padding: 5px 16px;

    @include main {
        padding: 5px;
    }

    @include oldschool {
        padding: 3px 7px;
        line-height: 16px;
    }
}
.balance-loader {
    display: inline-block;
    position: static;
    margin: 0 50px;
    vertical-align: middle;
    padding: 0;
    background-color: transparent;
}
.header-buttons {
    display: inline-flex;
    align-items: center;

    &-authenticated {
        background: #313639;
        border-radius: 4px;
        line-height: 0;
        border: 0.5px solid #444;

        &.compact {
            border-radius: 100px;
            padding: 4px 4px 4px 2px;
        }

        .presto & {
            padding: 0;
        }
    }

    .button {
        padding: 7px 8px;
        line-height: 18px;

        &:last-child {
            border-radius: $border-radius-right;
        }

        &.balance {
            padding: 0;
            margin: 0 8px;
            color: $white-text;
        }
        &.button-deposit {
            border-radius: 0 4px 4px 0;
            &.compact {
                font-size: 22px;
                line-height: 1em;
                padding: 3px;
                width: 1em;
                height: 1em;
                border-radius: 50%;
            }
        }

        @include main {
            padding: 7px 5px;
            font-size: 12px;

            &.button-deposit.compact {
                font-size: 20px;
            }
        }

        @include oldschool {
            font-size: 12px;
            padding: 4px;

            &.button-deposit.compact {
                font-size: 18px;
            }
        }
    }

    .presto & {
        .button:first-of-type {
            padding-left: 0;
        }
    }
}

a.button {
    text-decoration: none;
    @include only_mini {
        text-decoration: none !important;
    }
}
.icon-deposit {
    width: 100%;
    height: 100%;
}
</style>
