<template>
    <div v-if="winners.length">
        <h3>{{ title }}</h3>
        <table>
            <tr v-for="winner in winners" :key="winner.uuid">
                <td v-if="winner.finalized">{{ formatDate(winner.finalized) }}</td>
                <td v-if="winner.winnerPhone">{{ hidePhoneNumber(winner.winnerPhone) }}</td>
                <td v-if="winner.amount">
                    <Currency :amount="winner.amount" :format="currencyFormat" :decimal="0" />
                </td>
            </tr>
        </table>
    </div>
</template>
<script>
import { helper } from '@/modules/core';
import { mapGetters, mapState } from 'vuex';
import { getter as platformGetter } from '@/modules/platform/store/const';
import { Currency } from '@/modules/core/components';

export default {
    name: 'ProgressiveJpResultsTable',
    components: { Currency },
    props: {
        winners: {
            type: Array,
            default: () => [],
        },
        title: {
            type: String,
        },
    },
    computed: {
        ...mapGetters({
            dateOptions: platformGetter.GET_DATE_OPTIONS, // Implement global date time BP-17850
        }),
        ...mapState({
            currencyFormat: (state) => state.platform.settings.currency.format,
        }),
    },
    methods: {
        formatDate(value) {
            const { date, month, year } = helper.formatDateTime(value, {
                toObject: true,
                ...this.dateOptions,
                isUtc: true,
            });
            return `${date}/${month}/${year}`;
        },
        hidePhoneNumber(value) {
            return value.slice(0, 1) + '*'.repeat(value.length - 4) + value.slice(-3);
        },
    },
};
</script>

<style lang="scss" scoped>
h3 {
    margin-bottom: 8px;
}
table,
th,
td {
    border: 1px solid $medium-grey;
    @extend %small-details-font-400;
}
table {
    border-collapse: collapse;
    color: $main-text;
    width: 100%;
}
td {
    padding: 4px 12px;
}
</style>
