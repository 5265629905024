export const action = {
    GET_MESSAGES: 'platform/messaging/getMessages',
    MARK_AS_CLICKED: 'platform/messaging/markAsClicked',
    RESET_MESSAGING: 'platform/messaging/resetMessaging',
    GET_ONSITE_MESSAGES_AND_COUNT: 'platform/messaging/getOnsiteMessagesAndCount',
};

export const mutation = {
    SET_MESSAGES: 'platform/messaging/setMessages',
    RESET_MESSAGING: 'platform/messaging/resetMessaging',
    SET_ONSITE_MESSAGE_STATUS: 'platform/messaging/setMessageStatus',
    SET_ONSITE_MESSAGES_AND_COUNT: 'platform/messaging/setOnsiteMessagesAndCount',
    SET_MESSAGES_ERROR: 'platform/messaging/setMessagesError',
    RESET_MESSAGES_ERROR: 'platform/messaging/resetMessagesError',
};

export const getter = {
    GET_NOTSEEN: 'platform/messaging/getNotseen',
};
