<template>
    <div class="tier-banner">
        <ImageIcon :src="tier.trophyImg" class="trophy-img" />
        <ImageIcon :src="bgImage" class="trophy-bg" />
        <Currency :contrast="false" :amount="payout" :format="settings.currency" class="congrats-text">
            <renderer :input="$t('ui.myBets.winBigText')" />
        </Currency>
    </div>
</template>

<script>
import { mapState, mapGetters } from 'vuex';
import { Currency } from '@/modules/core/components';
import { getter as platformGetter } from '@/modules/platform/store/const';

export default {
    name: 'BetSlipWinBanner',
    components: { Currency },
    props: {
        tier: {
            type: Object,
            required: true,
        },
        payout: {
            type: Number,
            required: true,
        },
    },
    computed: {
        ...mapState({
            currencySymbol: (state) => state.platform.settings.currency.symbol,
        }),
        ...mapGetters({
            settings: platformGetter.GET_SETTINGS,
        }),
        bgImage() {
            return this.$mq.isXSmall ? this.tier.backgroundImgMobile : this.tier.backgroundImg;
        },
    },
};
</script>

<style lang="scss" scoped>
.tier-banner {
    max-width: 350px;
    width: 100%;
    position: relative;
    display: flex;
    padding: 8px 12px 16px 12px;
    column-gap: 16px;
    align-items: center;
    margin: auto;

    .trophy {
        &-img {
            height: 60px;
            width: auto;
        }

        &-bg {
            position: absolute;
            left: 0;
            right: 0;
            width: 100%;
            height: 100%;
        }
    }

    .congrats-text {
        @extend %body-normal-font-400;

        ::v-deep {
            .win-big-text,
            .symbol,
            .amount {
                text-transform: uppercase;
                @extend %body-big-font-700;
            }
        }
    }
}
</style>
