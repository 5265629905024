<template>
    <PrestoImageWrapper>
        <a
            class="link-image"
            :href="linkUrl"
            :target="linkTarget"
            :class="(imageAlign || 'full-width') && cssClass"
            @click="trackImageClick"
        >
            <img :src="`/${imageUrl}`" />
        </a>
    </PrestoImageWrapper>
</template>
<script>
import { getter as platformGetter } from '@/modules/platform/store/const';
import { getter as authGetter } from '@/modules/platform/store/modules/auth/const';
import { mapGetters } from 'vuex';
import { isValidAlignment } from '@/js/cms';
import { linkTarget } from '@/components/content/content-const';
import PrestoImageWrapper from '@/components/Fragments/PrestoImageWrapper';

// TODO: re-use ResponsiveImage comp, it has same logic & style for single image source
export default {
    name: 'LinkImage',
    components: { PrestoImageWrapper },
    props: {
        data: {
            type: Object,
            default: () => {
                return {};
            },
        },
        src: String,
        link: String,
        align: String,
        target: String,
    },
    computed: {
        ...mapGetters({
            isAuthenticated: authGetter.IS_AUTHENTICATED,
            country: platformGetter.GET_COUNTRY,
        }),
        imageUrl() {
            return this.data.img || this.src;
        },
        imageAlign() {
            if (isValidAlignment(this.data.align)) {
                return this.data.align;
            }
            return this.align;
        },
        linkUrl() {
            return this.data.link || this.link;
        },
        linkTarget() {
            return linkTarget[this.data.subset] || this.target;
        },
        cssClass() {
            return this.data.cssClass;
        },
    },
    methods: {
        trackImageClick() {
            this.$gtm.query({
                event: 'link_image_internal_click',
                link: this.linkUrl,
                linkTarget: this.linkTarget,
                jurisdiction: this.country,
                isAuthenticated: this.isAuthenticated,
                pageUrl: this.$route.path,
            });
        },
    },
};
</script>

<style scoped lang="scss">
.link-image {
    display: block;

    img {
        max-width: 100%;
    }

    &.align-center {
        text-align: center;
    }

    &.align-left {
        text-align: left;
    }

    &.align-right {
        text-align: right;
    }

    &.full-width img {
        width: 100%;
    }
}
</style>
