<template>
    <div v-if="actualAnnouncements && actualAnnouncements.length" class="announcement">
        <div v-for="(announcement, index) in actualAnnouncements" :key="index" :class="{ 'notify warning': !announcement.isTemplate }">
            <renderer :input="announcement.message" />
        </div>
    </div>
</template>

<script>
import { mapState } from 'vuex';
import { helper } from '@/modules/core';

export default {
    name: 'Announcement',
    data() {
        return {
            actualAnnouncements: [],
        };
    },
    computed: {
        ...mapState({
            announcements: (state) => state.platform.content.announcement,
        }),
    },
    watch: {
        announcements(value) {
            if (value && value.length) {
                this.formulateAnnouncements();
            }
        },
        $route() {
            if (this.announcements && this.announcements.length) {
                this.formulateAnnouncements();
            }
        },
    },
    methods: {
        formulateAnnouncements() {
            this.actualAnnouncements = this.announcements
                ?.map((i) => ({ ...i, isTemplate: this.isHtmlTemplate(i.message) }))
                .filter((announcement) => {
                    return helper.checkPathForMatchingCurrentRoute(this.$route.path, announcement.path);
                });
        },
        isHtmlTemplate(ms) {
            const reg = new RegExp('^<[a-zA-Z]+', 'g');
            return typeof ms === 'string' && reg.test(ms.trim());
        },
    },
};
</script>

<style lang="scss" scoped>
.announcement {
    margin: 10px 20px;

    ::v-deep {
        .notify {
            margin-bottom: 0;
        }

        h1 {
            @extend %body-big-font-500;
            margin: 0 0 4px;
        }

        p {
            @extend %body-normal-font-400;
        }

        a {
            font-weight: $font-weight-500;
        }
    }
}
</style>
