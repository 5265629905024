<template>
    <div
        v-if="betCount && !isPlaced"
        class="open-betslip"
        :class="{
            pointer: pointer,
            'open-betslip-reverted': !isPresto,
        }"
        @click="openBetslip"
    >
        <div class="container" :style="containerStyle">
            <div class="first table">
                <div>
                    <SvgIcon :icon-id="sportIcon" class="sport-icon row-cell align-middle" />
                </div>
                <div class="bets-count row-cell align-middle">{{ betCount }}</div>
            </div>
            <div class="middle" :class="{ 'open-betslip-text': $mq.mtVerySmall || $mq.mtSmall }">
                <div class="text row-cell align-middle text-wrap">{{ openTitle }}</div>
                <div v-if="!allPricesOffline" class="odds">
                    {{ $t('ui.openBetslip.odds') }}: {{ $numberFormat(odds, undefined, undefined, ' ') }}
                </div>
            </div>
            <div class="last">
                <SvgIcon v-if="iconId" :icon-id="iconId" class="icon" />
            </div>
        </div>
        <Popover class="open-betslip-popover">
            <template v-if="!isShowCountdownOnly" slot="left">
                <span v-if="isCurrentWinBonus" class="bold" :class="{ 'open-betslip-text': $mq.isXXSmall }">
                    {{ bonusInfoMessage }}
                </span>
                <span v-if="isShowLegInfoMessage">
                    <span v-if="isCurrentWinBonus" class="bold open-betslip-symbol">&#183;</span>
                    <span class="text-wrap" :class="{ 'open-betslip-text': !isCurrentWinBonus && $mq.isXXSmall }">
                        {{ legInfoMessage }}
                    </span>
                </span>
            </template>
            <template
                v-if="isShowVirtualRoundTimer && (isShowCountdownOnly || (!isShowCountdownOnly && $mq.isXSmallMin))"
                :slot="isShowCountdownOnly ? 'left' : 'right'"
            >
                <span class="virtual-round-timer">
                    <span class="kick-off-label">
                        <template v-if="isShowMatchday">
                            <span :class="{ highlight: stopCountdown }">
                                {{ $t('ui.openBetslip.matchday') }}
                                {{ upcomingVirtualRound.gameRoundName }}
                            </span>
                            <span v-if="stopCountdown" class="highlight bold">
                                {{ $t('ui.openBetslip.hasStarted') }}
                            </span>
                        </template>
                        <span v-if="!stopCountdown" :class="{ 'open-betslip-text': isShowCountdownOnly }">
                            {{ $t('ui.openBetslip.kicksOff') }}
                        </span>
                    </span>
                    <template v-if="isShowVirtualRoundTimer && stopCountdown && $mq.isSmallMin && $mq.isBeforeSmall">
                        <span v-if="$mq.isBeforeSmallMin" class="bold open-betslip-symbol">&#183;</span>
                        {{ $t('ui.openBetslip.betOn') }}
                        <span class="bold">
                            {{ $t('ui.openBetslip.matchday') }}
                            {{ getNextRoundNumber() }}
                        </span>
                    </template>
                    <span
                        v-if="!stopCountdown"
                        class="kick-off-timer bold"
                        :class="{
                            highlight: highlightCountdown,
                            flash: flashCountdown,
                            'open-betslip-text': isShowCountdownOnly,
                        }"
                    >
                        {{ remainingTime }}
                    </span>
                </span>
            </template>
        </Popover>
    </div>
</template>

<script>
import { mapState, mapGetters } from 'vuex';
import { deviceType, helper } from '@/modules/core';
import { prestoScrollTop } from '@/modules/core/utils/prestoUtils';

import { action } from '@/store/const';

import { getter as platformGetter } from '@/modules/platform/store/const';

import { sportType } from '@/modules/sport';
import { getter as sportGetter } from '@/modules/sport/store/const';
import { action as betslipAction, getter as betslipGetter } from '@/modules/sport/store/modules/betslip/const';

import Popover from '@/modules/sport/components/Popover';

const countdownMilestones = {
    SHOW: 180,
    HIGHLIGHT: 60,
    FLASH: 30,
    END: 0,
    HIDE: -10,
};

export default {
    name: 'OpenBetslip',
    components: { Popover },
    props: {
        flow: {
            type: String,
            default: 'row nowrap',
        },
        height: {
            type: Number,
            default: 45,
        },
        justify: {
            type: String,
            default: 'center',
        },
        align: {
            type: String,
            default: 'center',
        },
        iconId: {
            type: String,
            default: '',
        },
        simple: {
            type: Boolean,
            default: true,
        },
        pointer: {
            type: Boolean,
            default: false,
        },
    },
    data() {
        return {
            isPresto: deviceType.isPresto(),
            remainingTime: null,
            countdown: null,
            showCountdown: false,
            highlightCountdown: false,
            flashCountdown: false,
            stopCountdown: false,
            virtualRoundIndex: null,
        };
    },
    computed: {
        isShowCountdownOnly() {
            return this.isShowVirtualRoundTimer && !this.stopCountdown && this.$mq.isXXSmall;
        },
        legInfoMessage() {
            return this.$t('ui.openBetslip.legs', {
                legs: this.legInfoData.selectionsToNextTier,
                bonuses: this.legInfoData.nextTierPercentage,
                winBonus: this.$t(`ui.openBetslip.${this.countryCodeIs.KE ? 'winBoost' : 'winBonus'}`),
                moreSelectionSuffix: this.legInfoData.selectionsToNextTier === 1 ? '' : 's ',
            });
        },
        isCurrentWinBonus() {
            return !!this.legInfoData?.currentWinBonus;
        },
        isShowLegInfoMessage() {
            const isRightSize =
                (!this.isCurrentWinBonus && this.$mq.isSmallestMin) ||
                (!this.isShowVirtualRoundTimer && this.$mq.isXSmallMin) ||
                (this.isShowVirtualRoundTimer && !this.stopCountdown && this.$mq.isBeforeSmallMin) ||
                (this.isShowVirtualRoundTimer && this.stopCountdown && this.$mq.isMediumMin);
            return isRightSize && this.legInfoData.currentWinBonus < this.totalBonus;
        },
        isShowMatchday() {
            return (
                !this.isShowCountdownOnly &&
                this.isShowVirtualRoundTimer &&
                ((!this.stopCountdown && this.$mq.isSSmallMin) || (this.stopCountdown && this.$mq.isBeforeSmallMin))
            );
        },
        bonusInfoMessage() {
            return this.$t(`ui.openBetslip.${this.countryCodeIs.KE ? 'boosts' : 'bonuses'}`, {
                currentBonus: this.legInfoData.currentWinBonus,
                totalBonus: this.totalBonus,
            });
        },
        isShowVirtualRoundTimer() {
            return this.isVirtual && !this.isPresto && this.showCountdown;
        },
        ...mapState({
            rounds: (state) => state.sport.virtualSports.rounds, // getter BP-16141
            upcomingVirtualRound() {
                return this.rounds[this.virtualRoundIndex];
            },
            legBonus: (state) => state.platform.settings.bonus,
        }),
        ...mapGetters({
            odds: betslipGetter.GET_TOTAL_ODDS,
            allPricesOffline: betslipGetter.ALL_PRICES_OFFLINE,
            sportType: sportGetter.GET_SPORT_TYPE,
            isVirtual: sportGetter.IS_VIRTUAL_SPORT,
            upcomingVirtualRoundIndex: sportGetter.GET_UPCOMING_VIRTUAL_ROUND_INDEX,
            selectedBetslip: betslipGetter.GET_SELECTED_BETSLIP,
            legInfoData: betslipGetter.GET_LEG_INFO_DATA,
            totalBonus: betslipGetter.GET_TOTAL_BONUS,
            countryCodeIs: platformGetter.COUNTRY_CODE_IS,
        }),
        betCount() {
            const { selectedBetsIds } = this.selectedBetslip;
            return selectedBetsIds.length;
        },
        isPlaced() {
            const { status } = this.selectedBetslip;
            return status.Placed;
        },
        openTitle() {
            return this.$t(`ui.openBetslip.${this.isVirtual ? 'virtualsBetslip' : 'sportsBetslip'}`);
        },
        sportIcon() {
            return this.sportType === sportType.SPORT ? 'icon-betslip' : `icon-${this.sportType}`;
        },
        containerStyle() {
            return {
                flexFlow: this.flow,
                minHeight: this.height + 'px',
                alignItems: this.align,
            };
        },
    },
    watch: {
        rounds() {
            this.setUpcomingRoundIndex();
            this.launchCountdown();
        },
        betCount(betCount) {
            if (betCount) {
                this.launchCountdown();
            } else {
                this.clearCountDown();
            }
        },
    },
    created() {
        if (this.isVirtual) {
            this.setUpcomingRoundIndex();
            this.launchCountdown();
        }
        this.$store.dispatch(betslipAction.LOAD_PRESTO_BETSLIP);
    },
    beforeDestroy() {
        clearInterval(this.countdown);
    },
    methods: {
        openBetslip() {
            this.$store.dispatch(action.SET_BETSLIP_STATE, true);
            this.$emit('close');
            prestoScrollTop(this.$router);
        },
        getNextRoundNumber() {
            return parseInt(this.upcomingVirtualRound?.gameRoundName) + 1;
        },
        setUpcomingRoundIndex() {
            if (this.rounds.length && !this.virtualRoundIndex) {
                this.virtualRoundIndex = this.upcomingVirtualRoundIndex;
            }
        },
        launchCountdown() {
            if (!this.isVirtual || this.isPresto || !this.upcomingVirtualRound || !this.betCount || this.countdown) {
                return;
            }
            let remainingSeconds = parseInt((this.upcomingVirtualRound.bettingClosesTime - new Date().getTime()) / 1000);
            this.countdown = setInterval(
                function () {
                    if (remainingSeconds <= countdownMilestones.SHOW && remainingSeconds > countdownMilestones.HIDE) {
                        if (remainingSeconds > countdownMilestones.END) {
                            this.setRemainingTime(remainingSeconds);
                            this.highlightCountdown = remainingSeconds <= countdownMilestones.HIGHLIGHT;
                            this.flashCountdown = remainingSeconds <= countdownMilestones.FLASH;
                            this.showCountdown = true;
                        } else {
                            this.stopCountdown = true;
                            this.flashCountdown = false;
                            this.highlightCountdown = false;
                        }
                    } else if (remainingSeconds === countdownMilestones.HIDE) {
                        this.clearCountDown();
                        this.virtualRoundIndex += 1;
                        this.launchCountdown();
                    }
                    --remainingSeconds;
                }.bind(this),
                1000
            );
        },
        setRemainingTime(remainingSeconds) {
            const minutes = '0' + parseInt(remainingSeconds / 60, 10);
            let seconds = parseInt(remainingSeconds % 60, 10);
            seconds = seconds < 10 ? '0' + seconds : seconds;
            this.remainingTime = `${minutes}:${seconds}`;
        },
        clearCountDown() {
            this.showCountdown = false;
            this.stopCountdown = false;
            clearInterval(this.countdown);
            this.countdown = null;
            this.remainingTime = null;
        },
        $numberFormat: helper.numberFormat,
    },
};
</script>

<style scoped lang="scss">
@keyframes flash {
    50% {
        opacity: 0;
    }
}

.text-wrap {
    @include only_mini {
        white-space: pre-wrap;
    }
}

.slide-enter-active,
.slide-leave-active {
    transition: max-height 0.5s, opacity 0.5s;
    max-height: 25px;
    overflow: hidden;
}

.slide-enter,
.slide-leave-to {
    opacity: 0;
    max-height: 0;
}

.open-betslip {
    background: $open-betslip-background;
    color: $open-betslip-color;
    font-size: 14px;
    line-height: 14px;
    box-shadow: 2px 2px 0 $open-betslip-box-shadow-color;
    flex-shrink: 0; // fix for older versions of chrome

    @include only_mini {
        margin-left: -12px;
        margin-right: -12px;
    }

    &-reverted {
        display: flex;
        flex-direction: column-reverse;
    }

    &-popover {
        font-size: 12px;
    }

    &-text {
        font-size: 18px;
    }

    &-symbol {
        margin: 0 4px;
    }

    .virtual-round-timer {
        .highlight {
            color: $virtual-timer-color;
        }

        .kick-off-timer {
            &.flash {
                animation: flash 0.5s linear infinite;
            }
        }
    }

    .container {
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: 7px 12px;
        cursor: pointer;

        @include only_mini {
            @include skip_element;
        }

        .first {
            font-weight: bold;
            width: auto;

            .sport-icon {
                fill: $open-betslip-color;
                width: 21px;
                height: 21px;
            }

            .bets-count {
                padding: 5px 1px 5px 5px;
            }
        }

        .middle {
            display: flex;
            flex-wrap: wrap;
            overflow: hidden;
            padding: 0 12px;
            line-height: 20px;

            .text {
                margin-right: 12px;
                white-space: nowrap;
                font-weight: 700;
            }

            .odds {
                overflow: hidden;
                text-overflow: ellipsis;
                white-space: nowrap;
            }
        }

        .last {
            flex: 1;
            text-align: right;
            fill: $open-betslip-color;
        }

        .icon {
            width: 18px;
            height: 18px;
        }
    }

    &.pointer {
        position: relative;
        max-width: 100vw;

        &:before {
            content: '';
            width: 0;
            height: 0;
            border: 6px solid transparent;
            position: absolute;
            top: -12px;
            left: 50%;
            margin-left: -3px;
            border-bottom-color: $open-betslip-background;
        }
    }
}
</style>
