<template>
    <div>
        <FilterRadioItem
            v-for="(option, index) in options"
            :key="index"
            :radio-id="`options-${option.name}`"
            :checked="option.isCurrent"
            :label="option.label"
            @click.native.prevent="handleRadioItemClick(option)"
        />
    </div>
</template>

<script>
import { FilterRadioItem } from '@/modules/sport/components';

export default {
    name: 'FilterByMarket',
    components: { FilterRadioItem },
    props: {
        options: {
            type: Array[Object],
            required: true,
        },
    },
    methods: {
        handleRadioItemClick(item) {
            this.$emit('onClick', item);
        },
    },
};
</script>
