<template>
    <div class="selector-container table" data-test-id="sportCategory">
        <div class="row-cell align-middle">
            <template v-if="isPresto">
                <div class="category-selector presto" @click="togglePrestoDropdown()">
                    {{ currentCategory.name }}
                    <svgIcon class="icon-size-small category-selector-icon" :icon-id="icon" />
                </div>
                <ul v-show="isOpenedDropdown" class="dropdown">
                    <li
                        v-for="category in categories"
                        :key="`left-menu-category-${category.id}`"
                        :class="{ selected: category.id === currentCategoryId }"
                        @click="setCurrentCategoryId(category.id)"
                    >
                        {{ $t(`project.sports.${category.id}.text`) }}
                    </li>
                </ul>
            </template>
            <select v-else class="global-select" @click="trackMainNav" @change="setCurrentCategoryId($event.target.value)">
                <option
                    v-for="category in categories"
                    :key="`left-menu-category-${category.id}`"
                    :value="category.id"
                    :selected="String(category.id) === String(currentCategoryId)"
                >
                    {{ $t(`project.sports.${category.id}.text`) }}
                </option>
            </select>
        </div>
        <div class="row-cell align-middle">
            <slot />
        </div>
    </div>
</template>

<script>
import { mapGetters } from 'vuex';
import { deviceType } from '@/modules/core';

import { mutation, getter as generalGetter } from '@/store/const';
import { routeName } from '@/router/const-name';

export default {
    name: 'LeftMenuCategories',
    data() {
        return {
            isPresto: deviceType.isPresto(),
            isOpenedDropdown: false,
        };
    },
    computed: {
        ...mapGetters({
            currentCategoryId: generalGetter.GET_CURRENT_CATEGORY_ID,
            currentCategory: generalGetter.GET_CURRENT_CATEGORY,
            categories: generalGetter.GET_SORTED_CATEGORIES,
        }),
        icon() {
            return !this.isOpenedDropdown ? 'arrow_down' : 'icon-arrow-up';
        },
    },
    methods: {
        setCurrentCategoryId(value) {
            this.$router.push({ name: routeName.UPCOMING, params: { categoryId: value } });
            this.$store.commit(mutation.SET_CURRENT_CATEGORY, value);
            this.isOpenedDropdown = false;
        },
        togglePrestoDropdown() {
            this.isOpenedDropdown = !this.isOpenedDropdown;
            this.trackMainNav();
        },
        trackMainNav() {
            this.$gtm.query({
                event: 'main_nav',
                click_text: 'sports dropdown',
            });
        },
    },
};
</script>

<style scoped lang="scss">
.selector-container {
    background: $left-menu-selector-background;
    padding: 8px 12px;
    border-bottom: $border;
    min-height: 54px;

    > div:first-child {
        width: 100%;
    }
}

.category-selector {
    position: relative;
    background: white;
    border: $left-menu-selector-border;
    color: $left-menu-selector-color;
    font-size: 14px;
    font-weight: normal;
    line-height: 1.1em;
    padding: 6px 8px;
    width: 100%;
    height: 35px;
    border-radius: $border-radius;
    cursor: pointer;

    &.presto {
        line-height: 1.2rem;
    }
}

.dropdown {
    border: 1px solid $left-menu-dropdown-border;
    list-style: none;

    li {
        padding-left: 0.3rem;
        cursor: pointer;

        &:hover,
        &.selected {
            background: $field-focus;
            color: $white-text;
        }
    }
}

.category-selector-icon {
    position: absolute;
    right: 0.55rem;
    top: 0.55rem;
}
</style>
