<template>
    <div :class="['auto-cashout-container', errorClasses]">
        <Spinner v-if="autoCashoutOfferIsLoading" :visible="true" class="inset" />

        <!-- Auto CashOut Errors -->
        <div v-else-if="hasGlobalAutoCashOutError" class="error-container">
            <p class="auto-cashout-text">
                <renderer :input="autoCashOutErrorMessage" />
            </p>

            <Button v-if="autoCashOutOfferError.isLockedError" class="button button-accent" @click="getBetslipCashoutOffers">
                {{ $t('ui.common.tryAgain') }}
            </Button>
        </div>

        <!-- Auto CashOut Offer Set Successfully -->
        <div v-else-if="isCashoutSet" class="auto-cashout-success-container">
            <div class="auto-cashout-success-container-icon">
                <SvgIcon icon-id="icon-circle-checked" class="icon-size-small icon-auto-cashout-set" />
            </div>
            <div class="auto-cashout-success-msg">
                <p class="auto-cashout-text">{{ $t('ui.autoCashout.autoCashoutSet') }}</p>
                <p class="auto-cashout-offer-text">
                    {{ $t('ui.autoCashout.autoCashoutOfferCondition', { amount: acceptableOffer, currency: currencySymbol }) }}
                </p>
            </div>
            <Button class="button button-accent button-cancel-auto-cashout" @click="cancelAutoCashOutOffer">
                {{ $t('ui.common.cancel') }}
            </Button>
        </div>

        <!-- Auto CashOut BP-30876 fix -->
        <div v-else-if="hasNoMinMaxOffers" class="error-container">
            <p class="auto-cashout-text">
                {{ $t('ui.autoCashout.noOffersAvailable') }}
            </p>
        </div>

        <!-- Auto CashOut Offer Form -->
        <div v-else>
            <LabeledRangeSlider
                v-if="!isPresto"
                :step="0.01"
                :max="maxOffer"
                :min="minOffer"
                :value="+amountOffer"
                @input="setAutoCashOutValue($event, AUTO_CASHOUT_OFFER_INPUT_TYPE.slider)"
            />
            <div class="auto-cashout-form">
                <DecimalInputField
                    name="amount"
                    form-name="auto-cashout-form"
                    class="amount"
                    data-test-id="autoCashoutInput"
                    :v="$v.amountOffer"
                    :value="amountOffer"
                    :error-messages="errorMessages"
                    :currency-symbol="currencySymbol"
                    :disabled="inProgress"
                    :help-text="inputHelpText"
                    @value="setAutoCashOutValue($event, AUTO_CASHOUT_OFFER_INPUT_TYPE.input)"
                >
                    <template slot="label">
                        <span class="label-wrapper">
                            <span>{{ $t('ui.autoCashout.amountInputLabel') }}</span>
                        </span>
                    </template>
                </DecimalInputField>
                <div class="auto-cashout-form-action" @[autoCashOutClickEventName]="setAutoCashOutOffer">
                    <Button :disabled="isAutoCashOutDisabled" class="button button-primary btn-auto-cashout" @click="setAutoCashOutOffer">
                        {{ autoCashOutSetBtnText }}
                    </Button>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { mapState, mapActions, mapGetters, mapMutations } from 'vuex';

import { deviceType } from '@/modules/core';
import { getter as coreGetter } from '@/modules/core/store/const';
import LabeledRangeSlider from '@/components/LabeledRangeSlider.vue';
import { action as sportAction, getter as sportGetter, mutation as sportMutation } from '@/modules/sport/store/const';
import { getObjectField, numberFormat } from '@/modules/core/utils/helper';
import { between } from 'vuelidate/lib/validators';
import Button from '@/components/Button.vue';
import { getter as platformGetter } from '@/modules/platform/store/const';
import DecimalInputField from '@/modules/core/components/molecules/DecimalInputField.vue';

const AUTO_CASHOUT_OFFER_INPUT_TYPE = {
    slider: 'slider',
    input: 'input',
};

export default {
    name: 'AutoCashOut',
    components: { DecimalInputField, Button, LabeledRangeSlider },
    data() {
        return {
            inProgress: false,
            isPresto: deviceType.isPresto(),
            autoCashOutIntervalId: null,
            AUTO_CASHOUT_OFFER_INPUT_TYPE,
            touchedInputTypes: new Set(),
        };
    },
    computed: {
        ...mapState({
            betslip: (state) => state.sport.myBets.betslip,
            currencySymbol: (state) => state.platform.settings.currency.symbol,
        }),
        ...mapGetters({
            isLoading: coreGetter.IS_LOADING,
            autoCashOut: sportGetter.GET_AUTO_CASHOUT_OFFER,
            autoCashOutOfferError: sportGetter.GET_AUTO_CASHOUT_OFFER_ERRORS,
            requestOfferError: sportGetter.GET_CASHOUT_OFFER_ERRORS,
            amountOffer: sportGetter.GET_CASHOUT_AMOUNT_OFFER,
            user: platformGetter.GET_USER_SETTINGS,
            cashOutAvailabilityTime: platformGetter.GET_CASHOUT_AVAILABILITY_TIME,
            brandPreference: platformGetter.GET_BRAND_PREFERENCE,
        }),
        enableAutoCashoutPolling() {
            return getObjectField(this.brandPreference, 'enableAutoCashoutPolling');
        },
        hasGlobalAutoCashOutError() {
            return this.autoCashOut && !this.autoCashOutOfferError.isOfferOutOfRangeError && this.autoCashOutOfferError.message;
        },
        autoCashOutClickEventName() {
            return this.isPresto ? 'click' : null;
        },
        inputHelpText() {
            return !this.requestOfferError.isLockedError && !!Number(this.currentOffer)
                ? this.$t('ui.autoCashout.amountInputOffer', { offerAmount: this.currentOffer })
                : '';
        },
        isAutoCashOutDisabled() {
            return !this.amountOffer || this.amountOffer === 0 || this.isSetCashOutOfferLoading || this.$v.amountOffer.$invalid;
        },
        maxOffer() {
            return getObjectField(this.autoCashOut, 'maxOffer', 0);
        },
        currentOffer() {
            return getObjectField(this.autoCashOut, 'currentOffer', 0);
        },
        minOffer() {
            return getObjectField(this.autoCashOut, 'minOffer', 0);
        },
        isCashoutSet() {
            return getObjectField(this.autoCashOut, 'enabled', false);
        },
        acceptableOffer() {
            return numberFormat(getObjectField(this.autoCashOut, 'acceptableOffer', 0));
        },
        isSetCashOutOfferLoading() {
            return this.isLoading(sportAction.CREATE_AUTO_CASHOUT_OFFER);
        },
        autoCashOutSetBtnText() {
            return this.isSetCashOutOfferLoading ? this.$t('ui.cashout.processingOffer') : this.$t('ui.autoCashout.autoCashoutBtn');
        },
        autoCashOutErrorMessage() {
            return this.autoCashOutOfferError.isGenericError ? this.$t('ui.common.error.genericError') : this.autoCashOutOfferError.message;
        },
        errorClasses() {
            if (this.autoCashOutOfferError.isNetworkError || this.autoCashOutOfferError.isLockedError) {
                return 'warn';
            } else if (this.autoCashOutOfferError.isDead) {
                return 'error';
            }

            return '';
        },
        errorMessages() {
            let between;

            if (this.autoCashOutOfferError.isOfferOutOfRangeError) {
                between = this.autoCashOutOfferError.message;
            } else {
                between = this.$t('ui.autoCashout.amountMinMax', {
                    min: numberFormat(this.minOffer),
                    max: numberFormat(this.maxOffer),
                });
            }

            return { between };
        },
        autoCashoutOfferIsLoading() {
            return this.isLoading(sportAction.GET_BETSLIP_AUTO_CASHOUT_OFFER);
        },
        hasInPlaySelection() {
            return this.betslip.selections.some((selection) => selection.selection.inPlay);
        },
        hasNoMinMaxOffers() {
            return !this.minOffer || !this.maxOffer;
        },
        shouldNotReloadAutoCashoutOffer() {
            return this.autoCashOut && this.isCashoutSet;
        },
    },
    validations() {
        return {
            amountOffer: {
                between: between(this.minOffer, this.maxOffer),
            },
        };
    },
    mounted() {
        this.initBetslipAutoCashoutOffer();
    },
    beforeDestroy() {
        clearInterval(this.autoCashOutIntervalId);
    },
    methods: {
        numberFormat,
        ...mapActions({
            createAutoCashOutOffer: sportAction.CREATE_AUTO_CASHOUT_OFFER,
            getAutoCashoutOffer: sportAction.GET_BETSLIP_AUTO_CASHOUT_OFFER,
        }),
        ...mapMutations({
            setAutoCashOutAdjustedAmount: sportMutation.SET_AUTO_CASHOUT_OFFER,
        }),
        setAutoCashOutValue(value, inputType) {
            this.setAutoCashOutAdjustedAmount({ amountOffer: value });
            this.touchedInputTypes.add(inputType);
        },
        setAutoCashOutOffer() {
            if (this.isAutoCashOutDisabled) return;
            this.createAutoCashOutOffer({
                betslipId: this.betslip.id,
                acceptableOffer: this.amountOffer,
                inPlay: this.hasInPlaySelection,
                enabled: true,
            }).then(() => {
                this.$v.$touch();
                clearInterval(this.autoCashOutIntervalId);
            });
            this.trackAutoCashOutOffer({
                betslipId: this.betslip.id,
                action: 'request',
                actionType: 'set_offer',
                inputType: this.touchedInputTypes.join(','),
            });
            this.touchedInputTypes.clear();
        },
        cancelAutoCashOutOffer() {
            this.createAutoCashOutOffer({
                betslipId: this.betslip.id,
                acceptableOffer: this.autoCashOut.acceptableOffer,
                inPlay: this.hasInPlaySelection,
                enabled: false,
            });
            this.trackAutoCashOutOffer({ action: 'request', actionType: 'cancel_offer' });
            this.initiateAutoCashoutPolling();
        },
        getBetslipCashoutOffers(callback = null) {
            this.trackAutoCashOutOffer({ action: 'request', actionType: 'get_offer' });
            this.getAutoCashoutOffer(this.betslip).then(() => {
                if (this.autoCashOutOfferError.isDead) {
                    clearInterval(this.autoCashOutIntervalId);
                }

                if (callback) callback();
            });
        },
        initBetslipAutoCashoutOffer() {
            if (this.shouldNotReloadAutoCashoutOffer) return;
            this.getBetslipCashoutOffers(() => {
                this.setAutoCashOutAdjustedAmount({ amountOffer: this.amountOffer || this.maxOffer });
                if (!this.isCashoutSet) this.initiateAutoCashoutPolling();
            });
        },
        initiateAutoCashoutPolling() {
            if (this.enableAutoCashoutPolling && this.cashOutAvailabilityTime && !this.hasGlobalAutoCashOutError) {
                this.autoCashOutIntervalId = setInterval(() => {
                    this.getBetslipCashoutOffers();
                }, this.cashOutAvailabilityTime * 1000);
            }
        },
        trackAutoCashOutOffer(extraProps) {
            this.$gtm.query({
                event: 'auto_cashout_offer',
                userUuid: getObjectField(this.user, 'userUuid'),
                ...extraProps,
            });
        },
    },
};
</script>

<style scoped lang="scss">
.auto-cashout {
    &-text {
        @extend %body-normal-font-500;
    }
    &-form {
        display: flex;
        justify-content: space-between;
        overflow: hidden;

        @include only_mini {
            flex-direction: column;
            align-items: flex-start;
        }

        @include smartmin {
            flex-wrap: wrap;
        }
    }
    &-container {
        background-color: white;
        padding: 6px 6px 12px;

        &.warn {
            border: 1px solid $golden-brown;
        }

        &.error {
            border: 1px solid $error-red;
        }

        &-icon {
            width: 14px;
            height: 14px;
        }
    }
    &-offer-text {
        @extend %small-details-font-400;
        color: $dark-grey-3;
    }
    &-success-container {
        display: flex;
        align-items: center;

        .button-cancel-auto-cashout {
            margin-left: auto;
        }

        .icon-auto-cashout-set {
            fill: $green-success;
            margin-right: 8px;
            position: relative;
            top: 3px;
        }
    }
    &-form-action {
        margin-top: 12px;
        padding: 0 6px;

        @include only_mini {
            width: 100%;
        }
        @include smartmin {
            flex-grow: 1;
        }
    }
}

.btn-auto-cashout {
    width: 100%;
}

.input-field {
    flex: 1;
    margin-bottom: 0;
    margin-top: 12px;
    padding: 0 6px;
    @include smartmin {
        flex-basis: 160px;
    }

    label {
        width: 100%;
    }

    &::v-deep input:focus {
        border-color: $light-green;
    }

    @include only_mini {
        width: 100%;
        display: table;

        .input-field-wrapper {
            display: table;
            width: 100%;
        }
    }
}

.error-container {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.spinner.default-spinner.inset {
    padding: 0;
}
</style>
