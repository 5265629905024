<template>
    <div class="chips">
        <div class="chips-content">
            <h2 class="chips-title">{{ $t('ui.chips.notification.title') }}</h2>
            <div class="chips-sub-title"><renderer :input="$t('ui.chips.notification.subTitle')" @clickEvent="clickEvent" /></div>
            <button class="button-confirm button button-full button-primary" @click="$modal.show('chips-modal')">
                {{ $t('ui.chips.notification.getBetpawaChips') }}
            </button>
        </div>
    </div>
</template>

<script>
import { mapActions } from 'vuex';
import { action as authAction } from '@/modules/platform/store/modules/auth/const';

export default {
    name: 'ChipsNotification',
    mounted() {
        this.getChipsBalance();
    },
    methods: {
        ...mapActions({
            getChipsBalance: authAction.GET_CHIPS_BALANCE,
        }),
        clickEvent() {
            this.$gtm.query({
                event: 'chips_notification_read_more_click',
            });
        },
    },
};
</script>

<style lang="scss" scoped>
.chips {
    width: 100%;
    padding: 8px;
    &-content {
        padding: 16px;
    }
}
</style>
