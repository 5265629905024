<template>
    <PrestoImageWrapper v-intersection-observer="{ callback: onVisible, once: true }">
        <Banner :data="bannerData" @click.native="trackBannerClick" />
    </PrestoImageWrapper>
</template>
<script>
import { mapGetters } from 'vuex';
import { getter as platformGetter } from '@/modules/platform/store/const';
import { getter as authGetter } from '@/modules/platform/store/modules/auth/const';
import { helper, deviceType } from '@/modules/core';
import PrestoImageWrapper from '@/components/Fragments/PrestoImageWrapper';
import Banner from '@/components/content/Banner';

export default {
    name: 'BannersCollection',
    components: { PrestoImageWrapper, Banner },
    props: {
        data: Object,
    },
    data() {
        return {
            isPresto: deviceType.isPresto(),
            isImpressionOnVisible: false, // TODO: let's remove this `isImpressionOnVisible` hard-code in [BP-27141]
        };
    },
    computed: {
        ...mapGetters({
            isAuthenticated: authGetter.IS_AUTHENTICATED,
            country: platformGetter.GET_COUNTRY,
        }),
        bannersList() {
            const { bannerCollectionItems } = this.data.content;
            const bannersWithData = bannerCollectionItems.filter((item) => {
                const { data } = item.banner || {};
                const { attributes } = data || {};
                const hasWeight = item.weight && item.weight > 0;

                const isAvailable = !!helper.getObjectField(attributes, 'isBannerEnabled');
                const platformVisibility = helper.getObjectField(attributes, 'platformVisibility', {});
                const visibleOnPlatform = helper.isVisibleOnPlatform(platformVisibility);

                const { startDate, endDate } = item.schedule || {};
                const currentTime = new Date().getTime();
                const passStartDate = startDate ? currentTime > new Date(startDate).getTime() : true;
                const passEndDate = endDate ? new Date(endDate).getTime() > currentTime : true;

                return isAvailable && visibleOnPlatform && !!attributes && hasWeight && passStartDate && passEndDate;
            });

            return bannersWithData;
        },
        currentItem() {
            const item = helper.weightedRandom(this.bannersList);
            const { banner } = item || {};
            return helper.getObjectField(banner.data, 'attributes', {});
        },
        currentHref() {
            const { url, page } = this.currentItem || {};
            const { data } = page || {};

            const attributes = helper.getObjectField(data, 'attributes', {});
            const actualLinkItem = helper.getObjectField(attributes, 'links', []).find((link) => link.isActual);
            const actualLink = helper.getObjectField(actualLinkItem, 'link');
            return (actualLink && `/${actualLink}`) || url || '';
        },
        bannerData() {
            return {
                content: {
                    ...this.currentItem,
                    isCustomImageFormat: true,
                    link: this.currentHref,
                    image: {
                        data: [
                            {
                                ...this.currentItem.image.data,
                            },
                        ],
                    },
                },
            };
        },
    },
    mounted() {
        if (this.isPresto || !this.isImpressionOnVisible) {
            // TODO: let's remove this `isImpressionOnVisible` hard-code in [BP-27141]
            this.trackBannerImpresshion();
        }
    },
    methods: {
        trackBannerClick() {
            const { title, slug } = this.currentItem || {};
            this.$gtm.query({
                event: 'banner_collection_click',
                link: this.currentHref,
                title,
                slug,
                isAuthenticated: this.isAuthenticated,
                jurisdiction: this.country,
            });
        },
        trackBannerImpresshion() {
            const { title, slug } = this.currentItem || {};
            this.$gtm.query({
                event: 'banner_collection_impression',
                link: this.currentHref,
                title,
                slug,
                isAuthenticated: this.isAuthenticated,
                jurisdiction: this.country,
                isPresto: this.isPresto,
            });
        },
        onVisible() {
            if (this.isImpressionOnVisible) {
                // TODO: let's remove this `isImpressionOnVisible` hard-code in [BP-27141]
                this.trackBannerImpresshion();
            }
        },
    },
};
</script>
