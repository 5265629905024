<template>
    <LoadingElementCore :text="text" :hide-progress-bar="isPresto" />
</template>

<script>
import { deviceType } from '@/modules/core';
import LoadingElementCore from '@/components/Fragments/LoadingElementCore.vue';

export default {
    name: 'LoadingElement',
    components: { LoadingElementCore },
    props: {
        text: {
            type: String,
            default: '',
        },
    },
    data() {
        return {
            isPresto: deviceType.isPresto(),
        };
    },
};
</script>
