<template>
    <div class="view-all-button">
        <router-link
            :to="{ name: routeName.MY_BETS, params: { section } }"
            class="button button-accent button-full"
            @click.native="closeBetSlip"
        >
            {{ $t('ui.common.viewAll') }}
        </router-link>
    </div>
</template>

<script>
import { routeName } from '@/router/const-name';
import { action } from '@/store/const';

export default {
    name: 'ViewAllButton',
    props: {
        section: {
            type: String,
            required: true,
        },
    },
    data() {
        return {
            routeName,
        };
    },
    methods: {
        closeBetSlip() {
            this.$store.dispatch(action.SET_BETSLIP_STATE, false);
        },
    },
};
</script>

<style scoped lang="scss">
.view-all-button {
    padding: 10px 12px;
}
</style>
