<template>
    <InputField
        :name="name"
        :type="type"
        :attrs="{
            ...{
                placeholder: placeholder,
                ...(isPin && { maxLength: 4 }),
                ...(isPin && { inputmode: 'numeric' }),
            },
            ...$attrs,
        }"
        :form-name="formName"
        :value="value"
        :help-text="!helpDisabled && (helpText || $t(`ui.common.${pinOrPassword}.helpText`))"
        :v="v"
        :error-messages="errors"
        @value="$emit('value', $event)"
        @optional:click="$emit('optional:click', $event)"
    >
        <template slot="label">{{ label || $t(`ui.common.${pinOrPassword}.label`) }}</template>
        <template slot="optional">
            <slot name="optional" />
        </template>
    </InputField>
</template>

<script>
import { mapGetters } from 'vuex';

import { InputField } from '@/modules/core/components';

import { getter } from '@/store/modules/translations/const';
import { getter as platformGetter } from '@/modules/platform/store/const';

export default {
    name: 'Password',
    components: { InputField },
    props: {
        name: {
            type: String,
            default: 'password',
        },
        formName: {
            type: String,
            required: true,
        },
        type: {
            type: String,
            default: 'password',
        },
        pin: {
            type: Boolean,
        },
        label: {
            type: String,
        },
        helpDisabled: {
            type: Boolean,
            default: false,
        },
        helpText: {
            type: String,
        },
        error: {
            type: String,
        },
        value: {
            default: '',
        },
        validator: {
            type: Function,
            default: () => true,
        },
        v: {
            type: Object,
        },
        errorMessages: {
            type: Object,
        },
    },
    computed: {
        ...mapGetters({
            pinOrPassword: getter.PIN_OR_PASSWORD,
            brandPreference: platformGetter.GET_BRAND_PREFERENCE,
        }),
        placeholder() {
            return this.$t(`ui.common.${this.pinOrPassword}.placeholder`);
        },
        isPin() {
            return this.pin || !!this.brandPreference.pin;
        },
        passwordError() {
            return this.$t(`ui.common.${this.pinOrPassword}.passwordError`);
        },
        errors() {
            const defaultErrors = {
                numeric: this.passwordError,
                required: this.passwordError,
                minLength: this.passwordError,
            };
            return { ...defaultErrors, ...this.errorMessages };
        },
    },
};
</script>
