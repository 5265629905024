<template>
    <span v-bind="countryFlagAttributes" />
</template>

<script>
import { mapGetters } from 'vuex';
import { helper } from '@/modules/core';
import { getter as platformGetter } from '@/modules/platform/store/const';

export default {
    name: 'CountryFlag',
    computed: {
        ...mapGetters({
            brandPreference: platformGetter.GET_BRAND_PREFERENCE,
            country: platformGetter.GET_COUNTRY,
        }),
        countryFlagUrl() {
            return helper.getObjectField(this.brandPreference, 'countryFlag.url');
        },
        countryFlagAttributes() {
            const countyClass = !this.countryFlagUrl && this.country ? `flag ${this.country}` : 'flag';
            return {
                class: countyClass,
                style: this.countryFlagUrl && `background-image: url('${this.countryFlagUrl}');`,
            };
        },
    },
};
</script>
