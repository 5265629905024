import { paystackOptions } from '@/modules/payment';

/**
 * isPaystackOption
 * @param {String} provider - depositTypeName field from deposit option
 * @returns {Boolean}
 */
const isPaystackOption = (provider) => {
    return paystackOptions.includes(provider?.toLowerCase());
};

export default isPaystackOption;
