import Vue from 'vue';

import { casinoGameMode, casinoProviderType, sortType, toolbarIconType } from '@/js/casino-const';

import { actionLoader } from '@/modules/core';
import { getObjectField } from '@/modules/core/utils/helper';
import { isNumber } from '@/modules/core/utils/number/isNumber';

import { getter as translationsGetter } from '@/store/modules/translations/const';

import { casino } from '@/modules/platform/endpoints';
import { getter as authGetter } from '@/modules/platform/store/modules/auth/const';
import { getter as platformGetter, action as platformAction, mutation as platformMutation } from '@/modules/platform/store/const';

import { getter, action, mutation } from '@/store/modules/casino/const';
import { getLocalTypes } from '@/store/utils';

const CASINO_GAMES_CACHING_TIME_IN_MINUTES = 10; // Store for 10 minutes

const state = {
    layout: toolbarIconType.TILE,
    games: {},
    favorites: [],
    error: null,
    gameLaunchError: null,
    gamesCollection: [], // remove for old casino
    gamesSortBy: sortType.POPULAR,
    favoriteGamesSortBy: sortType.FAVOURITES_ADDED_DESC,
};
const _getter = getLocalTypes(getter);

const getters = {
    [_getter.GET_GAME_COLLECTION]: ({ gamesCollection }) => {
        return gamesCollection
            .filter((game) => game.enabled)
            .sort((a, b) => {
                if (a.position > b.position) return 1;
                if (a.position < b.position) return -1;
                return 0;
            });
    },
    [_getter.GET_FAVORITE_GAMES]: ({ platform }, getters) => {
        const sortedGameCollection = getters[_getter.GET_GAME_COLLECTION];
        const favoriteGamesIds = [...getters[_getter.GET_FAVORITE_GAMES_IDS]].reverse();
        return favoriteGamesIds
            .map((favId) => sortedGameCollection.find(({ id }) => String(id) === String(favId)))
            .filter((game) => !!game);
    },
    [_getter.GET_GAME_COLLECTION_BY_CATEGORIES]: ({ gamesCollection }, getters) => {
        const sortedGameCollection = getters[_getter.GET_GAME_COLLECTION];
        const filteredGameCollection = {};

        sortedGameCollection.forEach((game) => {
            game.categories.forEach((category) => {
                if (!category.length) return;
                filteredGameCollection[category] = [...(filteredGameCollection[category] || []), game];
            });
        });
        return filteredGameCollection;
    },
    [_getter.GET_GAME_CATEGORY_BY_NAMES]: (state, getters, rootState, rootGetters) => {
        const { brandGamingIntegration } = rootGetters[platformGetter.GET_SETTINGS];
        const language = rootGetters[translationsGetter.GET_SELECTED_LANGUAGE];
        const categoriesByNames = {};
        getObjectField(brandGamingIntegration, 'categories', []).forEach((category) => {
            categoriesByNames[category.name] = {
                name: category?.name,
                text: category?.translations[language],
                position: category?.position,
                id: category?.uuid,
            };
        });
        return categoriesByNames;
    },
    [_getter.GET_GAME_CATEGORIES]: (state, getters) => {
        const gameCategories = Object.values(getters[_getter.GET_GAME_CATEGORY_BY_NAMES]).sort((a, b) => a.position - b.position);
        const games = getters[_getter.GET_GAMES].data || [];
        return gameCategories.filter((category) => games.filter((game) => game.categories.includes(category.id)).length > 0);
    },
    [_getter.GET_GAMES]: ({ games }) => {
        return games;
    },
    [_getter.GET_FAVORITE_GAMES_IDS]: ({ platform }, getters, rootState, rootGetters) => {
        if (!rootGetters[authGetter.IS_AUTHENTICATED]) {
            return [];
        }
        const { favorite_casino_games_ids: favGamesIds } = rootGetters[platformGetter.GET_PREFERENCE];

        return `${favGamesIds}`
            .split(',')
            .map((id) => parseInt(id))
            .filter((id) => isNumber(id));
    },
};

const _action = getLocalTypes(action);

const actions = {
    [_action.GET_CASINO_GAMES]: actionLoader(action.GET_CASINO_GAMES, ({ commit, getters }) => {
        return Vue.$http
            .get(casino.getCasinoGames)
            .then((response) => response.data.result)
            .then((games) => {
                const enabledGameCategoriesList = Object.keys(getters[_getter.GET_GAME_CATEGORY_BY_NAMES] || {});

                const collection = games.map((game) => ({
                    ...game,
                    gameId: `${getObjectField(game, 'nameShort') || game.gameId || game.name}`,
                    categories: (getObjectField(game, 'lobbyCategories') ?? []).filter((category) =>
                        enabledGameCategoriesList.includes(category)
                    ),
                }));
                commit(_mutation.SET_GAMES_COLLECTION, collection);
            })
            .catch((error) => {
                commit(_mutation.SET_GAMES, {
                    data: null,
                    isLoading: false,
                    error: error.message || error,
                });
            });
    }),
    [_action.GET_CASINO_GAMES_NEW]: actionLoader(action.GET_CASINO_GAMES_NEW, ({ commit, getters }) => {
        const currentGames = getters[_getter.GET_GAMES];
        const gamesExist = !!getObjectField(currentGames, 'data');
        const areGamesExpired = getObjectField(currentGames, 'expiration') < new Date().getTime();
        if ((gamesExist && !areGamesExpired) || currentGames.isLoading) {
            return Promise.resolve();
        }
        commit(_mutation.SET_GAMES, {
            isLoading: true,
        });
        return Vue.$http
            .get(casino.getCasinoGames)
            .then((response) => response.data.result)
            .then((games) => {
                const enabledGameCategoriesList = Object.values(getters[_getter.GET_GAME_CATEGORY_BY_NAMES] || {});
                const collection = games.map((game) => ({
                    ...game,
                    gameId: `${getObjectField(game, 'nameShort') || game.gameId || game.name}`,
                    categories: enabledGameCategoriesList
                        .filter(({ name }) => (getObjectField(game, 'lobbyCategories') ?? []).includes(name))
                        .map(({ id }) => id),
                }));
                commit(_mutation.SET_GAMES, {
                    data: collection,
                    expiration: new Date(new Date().getTime() + CASINO_GAMES_CACHING_TIME_IN_MINUTES * 60000).getTime(),
                    isLoading: false,
                    error: null,
                });
            })
            .catch((error) => {
                commit(_mutation.SET_GAMES, {
                    data: null,
                    isLoading: false,
                    error: error.message || error,
                });
                Vue.$sentry.withScope((scope) => {
                    scope.setExtras({ error });
                    scope.setTag('statusCode', error.statusCode);
                    scope.setTag('config', 'unavailable');
                    scope.setLevel('fatal');
                    Vue.$sentry.captureMessage('FATAL_ERROR_CASINO_GAMES');
                });
            });
    }),
    [_action.OPEN_CASINO_GAME]: actionLoader(
        action.OPEN_CASINO_GAME,
        ({ commit, rootGetters }, { name, provider, providerGameId, redirectFn, lobbyUrl }) => {
            const language = rootGetters[translationsGetter.GET_SELECTED_LANGUAGE];
            return Vue.$http
                .post(casino.casinoLauncherV2, {
                    provider,
                    mode: casinoGameMode.real,
                    lobbyUrl: lobbyUrl ? window.location.origin + lobbyUrl : window.location.href,
                    providerGameId,
                    language,
                })
                .then(({ data }) => {
                    Vue.$gtm.query({
                        event: Vue.$gtm.makeValidEventName(
                            `casino_lobby_${provider === casinoProviderType.NATIVE ? 'native' : provider}_game_launch`
                        ),
                        game_name: name,
                    });
                    if (redirectFn) {
                        redirectFn();
                    }
                    window.location.href = data.launchUrl || '';
                })
                .catch(({ errorCode }) => {
                    commit(_mutation.SET_GAME_LAUNCH_ERROR, { gameId: name, errorCode });
                });
        }
    ),
    [_action.ADD_FAVORITE_GAME]({ dispatch, commit, getters }, favorites) {
        const currentFavs = getters[_getter.GET_FAVORITE_GAMES_IDS];
        const savedValue = currentFavs.join(',');
        const newValue = favorites.join(',');
        if (savedValue.localeCompare(newValue) !== 0) {
            commit(platformMutation.SET_FAVORITE_CASINO_GAME, newValue, { root: true });
            dispatch(
                platformAction.PUT_DERIVED_DATA,
                {
                    key: 'favorite_casino_games_ids',
                    value: newValue === '' ? 'null' : newValue,
                },
                { root: true }
            ).catch(({ errorCode }) => {
                commit(_mutation.SET_GAME_LAUNCH_ERROR, { newValue, errorCode });
            });
        }
    },
};

const _mutation = getLocalTypes(mutation);

const mutations = {
    [_mutation.SET_GAMES_COLLECTION](state, collection) {
        state.gamesCollection = collection;
    },
    [_mutation.SET_GAMES_SORT_BY](state, value) {
        state.gamesSortBy = value !== state.gamesSortBy ? value : null;
    },
    [_mutation.SET_FAVOURITE_GAMES_SORT_BY](state, value) {
        state.favoriteGamesSortBy = value !== state.favoriteGamesSortBy ? value : null;
    },
    [_mutation.SET_GAMES](state, { data, error, isLoading, expiration }) {
        state.games = { data, error, isLoading, expiration };
    },
    [_mutation.SET_GAME_LAUNCH_ERROR](state, value = null) {
        state.gameLaunchError = value;
    },
    [_mutation.SET_CASINO_LAYOUT](state, value) {
        state.layout = value;
    },
};

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations,
};
