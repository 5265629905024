<template>
    <div class="page join-now-page">
        <AuthWrapper :tab-name="tabType.JOIN_NOW" :is-router-tab-link="true" />
    </div>
</template>

<script>
import { authType } from '@/modules/platform';

import PageMixin from '@/components/Pages/Page.mixin';
import AuthenticationMixin from '@/modules/platform/components/mixins/Authentication.mixin';
import SEOMixin from '@/components/Pages/SEO.mixin';

import AuthWrapper from '../AuthWrapper.vue';

export default {
    name: 'JoinNowPage',
    components: { AuthWrapper },
    mixins: [PageMixin, AuthenticationMixin, SEOMixin],
    data() {
        return {
            tabType: authType,
        };
    },
};
</script>
