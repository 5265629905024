<template>
    <div v-if="isAuthenticated" class="self-exclusion">
        <div class="self-exclusion-title">{{ $t('ui.selfExclusionPeriod.title') }}</div>
        <div class="self-exclusion-caption">{{ $t('ui.selfExclusionPeriod.caption', { duration: captionDurationText }) }}</div>
        <form class="self-exclusion-form" @submit.prevent="exclude">
            <InputField
                class="self-exclusion-form-item self-exclusion-form-period"
                type="number"
                name="period"
                :form-name="formName"
                :attrs="{
                    placeholder: $t('ui.selfExclusionPeriod.inputPlaceholder'),
                    inputMode: 'numeric',
                }"
                :value="form.period"
                :v="$v.form.period"
                :error-messages="errorMessages"
                :help-text="reactivationMessage"
                @value="setPeriod"
            >
                <template #label>{{ $t('ui.selfExclusionPeriod.inputLabel') }}</template>
            </InputField>
            <div class="self-exclusion-form-item self-exclusion-units">
                <div class="self-exclusion-units-container">
                    <div
                        v-for="unit in units"
                        :key="unit"
                        :class="['self-exclusion-units-item', { active: unit === form.unit }]"
                        @click="form.unit = unit"
                    >
                        {{ $t(`${unit}s`, { value: '' }) }}
                    </div>
                </div>
                <div v-if="$v.form.unit.$error" class="self-exclusion-units-error">
                    {{ $t('ui.common.form.error.required') }}
                </div>
            </div>
            <Password
                class="self-exclusion-form-item"
                name="self-exclusion-password"
                autocomplete="new-password"
                :form-name="formName"
                :value="form.password"
                help-disabled
                :v="$v.form.password"
                @value="setPassword"
            />
            <div v-if="!isProcessing && authError" class="notify error">
                <renderer :input="authError" />
            </div>
            <button class="self-exclusion-form-submit button button-full button-primary" :disabled="$v.$invalid || isProcessing">
                {{ buttonText }}
            </button>
        </form>
    </div>
</template>
<script>
import InputField from '@/modules/core/components/molecules/InputField.vue';
import { durationUnitsType } from '@/modules/core/utils/duration';
import Password from '@/components/Password.vue';
import { integer, required, minLength, numeric } from 'vuelidate/lib/validators';
import { mapGetters } from 'vuex';
import { getter as platformGetter } from '@/modules/platform/store/const';
import { getter as authGetter, action as authAction } from '@/modules/platform/store/modules/auth/const';
import { helper } from '@/modules/core';
import { getter as coreGetter } from '@/modules/core/store/const';

const PERIOD_UNITS = {
    [durationUnitsType.DAY]: {
        min: 1,
        max: 90,
    },
    [durationUnitsType.WEEK]: {
        min: 1,
        max: 12,
    },
    [durationUnitsType.MONTH]: {
        min: 1,
        max: 3,
    },
};
const units = Object.keys(PERIOD_UNITS);

export default {
    name: 'SelfExclusion',
    components: { Password, InputField },
    data() {
        return {
            formName: 'self-exclusion-form',
            form: {
                period: null,
                unit: '',
                password: null,
            },
            units,
            isExcluded: false,
        };
    },
    computed: {
        ...mapGetters({
            brandPreference: platformGetter.GET_BRAND_PREFERENCE,
            authError: authGetter.GET_AUTH_ERROR,
            isAuthenticated: authGetter.IS_AUTHENTICATED,
            dateOptions: platformGetter.GET_DATE_OPTIONS,
            isLoading: coreGetter.IS_LOADING,
        }),
        isProcessing() {
            return this.isLoading(authAction.SELF_EXCLUDE) || this.isExcluded;
        },
        unitsTextParams() {
            return units.reduce((acc, unit) => {
                acc.min = {
                    ...(acc.min || {}),
                    [unit]: helper.renderUnitDurationText(PERIOD_UNITS[unit].min, unit, unit === durationUnitsType.DAY),
                };
                acc.max = {
                    ...(acc.max || {}),
                    [unit]: helper.renderUnitDurationText(PERIOD_UNITS[unit].max, unit),
                };
                return acc;
            }, {});
        },
        errorMessages() {
            return {
                minValue: this.$t('ui.selfExclusionPeriod.inputMinValidationText', this.unitsTextParams.min),
                maxValue: this.$t('ui.selfExclusionPeriod.inputMaxValidationText', this.unitsTextParams.max),
                integer: this.$t('ui.common.form.error.integer', {
                    down: Math.floor(this.form.period),
                    up: Math.floor(this.form.period) + 1,
                }),
            };
        },
        estimatedReactivationDate() {
            if (!this.$v.form.period.$invalid && !this.$v.form.unit.$invalid && this.form.period) {
                return helper.addDurationToDate({ date: new Date(), unit: this.form.unit, value: Number(this.form.period) });
            }
            return null;
        },
        reactivationMessage() {
            if (this.estimatedReactivationDate) {
                const { date, month, year } = helper.formatDateTime(this.estimatedReactivationDate, {
                    ...this.dateOptions,
                    isUtc: true,
                    toObject: true,
                });
                return this.$t('ui.selfExclusionPeriod.reactivationText', { date: `${date}.${month}.${year}` });
            }
            return '';
        },
        captionDurationText() {
            return helper.renderUnitDurationText(PERIOD_UNITS[durationUnitsType.DAY].max, durationUnitsType.DAY);
        },
        buttonText() {
            if (this.isProcessing) {
                return this.$t('ui.common.loading');
            }
            const { period, unit } = this.form;
            if (!this.$v.form.period.$invalid && period && unit) {
                return this.$t('ui.selfExclusionPeriod.ctaButtonTextActive', { duration: helper.renderUnitDurationText(period, unit) });
            }
            return this.$t('ui.selfExclusionPeriod.ctaButtonText');
        },
    },
    validations() {
        const isPin = this.brandPreference.pin;
        const unitLimit = PERIOD_UNITS[this.form.unit] || PERIOD_UNITS[durationUnitsType.DAY];
        return {
            form: {
                period: {
                    integer,
                    minValue: (value) => value >= unitLimit.min,
                    maxValue: (value) => value <= unitLimit.max,
                },
                unit: { required },
                password: { required, minLength: minLength(4), ...(isPin && { numeric }) },
            },
        };
    },
    methods: {
        exclude() {
            const selfExcludedUntil = new Date(this.estimatedReactivationDate).toISOString();
            this.$store
                .dispatch(authAction.SELF_EXCLUDE, {
                    password: this.form.password,
                    selfExcludedUntil,
                })
                .then((isExcluded) => {
                    this.isExcluded = isExcluded;
                    this.$gtm.query({
                        event: 'self_exclude_confirm_button_click',
                        wrongPinOrPass: !!this.authError,
                        selfExcludedUntil,
                        period: this.form.period,
                        unit: this.form.unit,
                    });
                });
        },
        setPassword(value) {
            this.$v.$touch();
            this.form.password = value;
        },
        setPeriod(value) {
            this.form.period = value;
        },
    },
};
</script>

<style lang="scss">
.self-exclusion {
    padding: 16px 12px;
    margin: 16px 0;
    border-radius: 4px;
    border: 1px solid $medium-grey;
    background: $white-bg;

    .input-field {
        margin-bottom: 0;
    }

    &-title {
        @extend %body-big-font-500;
        color: $main-text;
        margin-bottom: 4px;
    }

    &-caption {
        @extend %body-normal-text;
    }

    &-form {
        &-item {
            margin-top: 12px;
            @include only_mini {
                display: table;
                width: 100%;
            }
        }
        &-submit {
            margin-top: 8px;
        }
    }

    &-units {
        &-container {
            display: flex;
            justify-content: space-between;

            @include oldschool {
                flex-wrap: wrap;
            }
        }

        &-error {
            @extend %form-validation-error;
        }

        &-item {
            display: flex;
            align-items: center;
            justify-content: center;
            flex: 1 0 0;
            border-radius: 4px;
            background: $light-grey;
            @extend %body-normal-text;
            text-align: center;
            padding: 7px;
            margin-right: 10px;
            border: 1px solid transparent;
            transition: all 200ms ease-in-out;
            text-transform: capitalize;

            @include oldschool {
                margin-right: 0;
                flex: 0 1 48%;
                &:last-of-type {
                    flex: auto;
                    width: 100%;
                    margin-top: 12px;
                }
            }

            &:last-of-type {
                margin-right: 0;
            }

            &.active {
                border: 1px solid $light-green;
                background: #f4fae8;
            }
        }
    }
}
</style>
