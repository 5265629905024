<template>
    <div class="modal-window">
        <a class="underline" @click="openModal()">
            {{ data.text }}
            <SvgIcon icon-id="icon-help" />
        </a>
        <Modal class="cms-modal" :name="modalId" :width="'100%'" @close="$modal.hide(modalId)">
            <div v-if="content" class="modal-body-content">
                <DynamicComponent v-for="(item, index) in content" :key="modalId + index" :data="item" :path="'content'" />
            </div>
            <div v-else-if="contentError">
                <renderer :input="contentError" />
            </div>
            <Spinner v-else :visible="true" class="inset" />
        </Modal>
    </div>
</template>
<script>
import { mapGetters, mapState } from 'vuex';
import { Spinner } from '@/modules/core/components';
import { DynamicComponent } from '@/modules/platform/components';
import { action as generalAction } from '@/modules/platform/store/const';

export default {
    name: 'Modalwindow',
    components: { DynamicComponent, Spinner },
    props: ['data'],
    computed: {
        ...mapState({
            contentError: (state) => state.platform.content.error.message,
        }),
        ...mapGetters({
            getText: generalAction.GET_TEXT,
        }),
        modalId() {
            return `cms-id-${this.modalContentId}`;
        },
        modalContentId() {
            return [this.data.id];
        },
        content() {
            const text = this.getText[this.modalContentId];
            return text && text.content;
        },
    },
    methods: {
        openModal() {
            this.$store
                .dispatch(generalAction.GET_MODAL_WINDOW, { id: this.modalContentId, silent: true })
                .then(() => this.$modal.show(this.modalId));
        },
    },
};
</script>

<style scoped lang="scss">
.modal {
    &-body-content *:first-child {
        margin-top: 0;
    }

    &-window {
        svg {
            width: 14px;
            height: 14px;
        }
    }
}
</style>
