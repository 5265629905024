<template>
    <BaseCashOut />
</template>

<script>
import { mapGetters, mapMutations, mapState } from 'vuex';
import { getter as sportGetter, mutation as sportMutation } from '@/modules/sport/store/const';

import BaseCashOut from '@/modules/sport/components/Fragments/Cashout/BaseCashOut.vue';

export default {
    name: 'NewCashOut',
    components: { BaseCashOut },
    computed: {
        ...mapState({
            betslip: (state) => state.sport.myBets.betslip,
            myBetsCashoutClicked: (state) => state.sport.ui.myBetsCashoutClicked,
        }),
        ...mapGetters({
            offerPolling: sportGetter.GET_CASHOUT_OFFER_POLLING,
        }),
    },
    created() {
        this.setIsConfirmCashOutReady(this.myBetsCashoutClicked || this.betslip.cashoutInfo?.displayingEnabled);
        if (!this.offerPolling.pollingEnabled) {
            this.setCashoutOfferError();
        }
    },
    methods: {
        ...mapMutations({
            setCashoutOfferError: sportMutation.SET_CASHOUT_OFFER_ERROR,
            setIsConfirmCashOutReady: sportMutation.SET_IS_CONFIRM_CASHOUT_READY,
        }),
    },
};
</script>

<style scoped lang="scss">
::v-deep .request-offer {
    padding: 12px;
    background-color: white;

    &.error {
        border: 1px solid $error-red;
    }

    &.warn {
        border: 1px solid $golden-brown;
    }

    .offer-message {
        .cashout-icon {
            margin: -3px 8px 0 0;
        }
    }

    @include only_mini {
        ::v-deep .button-media {
            display: block;
            width: 14px;
            height: 14px;
        }
    }
}

::v-deep .button-media {
    display: flex;
    justify-content: center;
    align-items: center;
}
</style>
