<template>
    <div class="casino-lobby no-margins">
        <ErrorPage
            v-if="allGamesLoadError"
            :title="$t('ui.casinoLobby.gamesLoadError.title')"
            :section-list="[$t('ui.casinoLobby.gamesLoadError.text')]"
            :img-src="gamesErrorImg"
            :button="{ text: $t('ui.errorPage.reloadPage'), emit: true }"
            @button:click="errorButtonClick()"
        />
        <div v-else>
            <CasinoPills />
            <div class="title-container">
                <h2>
                    <SvgIcon v-if="iconId" class="icon-size-small" :icon-id="iconId" />
                    {{ title }}
                </h2>
                <DropDown
                    class="uppercase"
                    name="casino-lobby"
                    :is-capitalize-menu-titles="true"
                    :label="$t('ui.casinoLobby.sort')"
                    :items="sortingOptions"
                    :is-open="sortingIsOpen"
                    :selected="gameSorting"
                    :is-label-underline="false"
                    right-sided
                    icon-id="icon-sort"
                    @value="setSorting($event)"
                    @toggle="handleToggleDropdown"
                />
            </div>
            <CasinoCollectionGrid
                :games="filteredGames"
                :games-count="gamesCount"
                :games-limit="gamesLimit"
                :is-favourites="!!shouldShowFavouritesOnly"
            />
        </div>
    </div>
</template>

<script>
import { deviceType } from '@/modules/core';
import { getter as coreGetter } from '@/modules/core/store/const';
import scrollSniffer from '@/js/directives/ScrollSniffer';
import { mapGetters, mapMutations, mapState } from 'vuex';
import CasinoPills from '@/components/content/CasinoPills.vue';
import { mutation as generalMutation } from '@/store/const';
import ErrorPage from '@/components/Pages/ErrorPage';
import CasinoCollectionGrid from '@/modules/casino/strapiComponents/components/CasinoCollectionGrid.vue';
import { DropDown } from '@/modules/core/components';
import { sortType } from '@/js/casino-const';
import { routeName } from '@/router/const-name';
import { getter as authGetter } from '@/modules/platform/store/modules/auth/const';
import { getObjectField } from '@/modules/core/utils/helper';
import {
    fillGamesGapWithFakeGames,
    getCasinoGamesLimitForGridPerRow,
    getGamesFilterFunction,
    getSortGamesByFavourites,
    sortGamesByName,
    sortGamesByPopular,
} from '@/modules/casino/utils/CasinoCollection-utils';
import { getter as casinoGetter, action as casinoAction, mutation as casinoMutation } from '@/store/modules/casino/const';
import { CasinoFilter, CasinoStaticCategories } from '@/modules/casino/utils/CasinoCollection-const';
import { ERROR_IMAGES } from '@/components/content/content-const';

const GAMES_ROWS_PER_PAGE = 10;
export default {
    name: 'CasinoAllGames',
    components: { DropDown, CasinoCollectionGrid, CasinoPills, ErrorPage },
    directives: {
        sniffer: scrollSniffer,
    },
    data() {
        return {
            sortingIsOpen: false,
            isPresto: deviceType.isPresto(),
            gamesErrorImg: ERROR_IMAGES.errorCasinoGamesLoad,
        };
    },
    computed: {
        ...mapGetters({
            isAuthenticated: authGetter.IS_AUTHENTICATED,
            isLoading: coreGetter.IS_LOADING,
            favoriteGamesIds: casinoGetter.GET_FAVORITE_GAMES_IDS,
            gameCategories: casinoGetter.GET_GAME_CATEGORIES,
            games: casinoGetter.GET_GAMES,
        }),
        ...mapState({
            gamesLoadError: (state) => state.casino.games.error,
            gamesSortBy: (state) => state.casino.gamesSortBy,
            favoriteGamesSortBy: (state) => state.casino.favoriteGamesSortBy,
        }),
        queryFavourites() {
            return this.$route.query[CasinoFilter.FAVOURITES];
        },
        sortingOptions() {
            const commonOptions = [
                { key: this.$t(`ui.casinoLobby.ascending`), value: sortType.NAME_ASC },
                { key: this.$t(`ui.casinoLobby.descending`), value: sortType.NAME_DESC },
            ];
            if (this.shouldShowFavouritesOnly) {
                return [
                    { key: this.$t(`ui.casinoLobby.favourites_added_desc`), value: sortType.FAVOURITES_ADDED_DESC },
                    { key: this.$t(`ui.casinoLobby.favourites_added_asc`), value: sortType.FAVOURITES_ADDED_ASC },
                    ...commonOptions,
                ];
            }
            return [{ key: this.$t(`ui.casinoLobby.pop`), value: sortType.POPULAR }, ...commonOptions];
        },
        categoryId() {
            const category = this.$route.query[CasinoFilter.CATEGORY];
            return category !== CasinoStaticCategories.ALL ? category : null;
        },
        gameSorting() {
            return this.shouldShowFavouritesOnly ? this.favoriteGamesSortBy : this.gamesSortBy;
        },
        category() {
            if (!this.categoryId) return null;
            return this.gameCategories.find(({ id }) => this.categoryId === id);
        },
        title() {
            if (this.categoryId) {
                return getObjectField(this.category, 'name', '');
            }
            if (this.shouldShowFavouritesOnly) {
                return this.$t(`ui.casinoLobby.favourites`);
            }
            return this.$t('ui.eventPage.category.all');
        },
        iconId() {
            if (this.category) {
                return this.category.iconId;
            }
            return null;
        },
        filteredGames() {
            if (!this.games.data) return fillGamesGapWithFakeGames([], this.gamesLimit);
            const filterFn = getGamesFilterFunction(this.shouldShowFavouritesOnly, this.favoriteGamesIds, this.categoryId);
            let sortFn;
            switch (this.gameSorting) {
                case sortType.FAVOURITES_ADDED_ASC:
                case sortType.FAVOURITES_ADDED_DESC:
                    sortFn = getSortGamesByFavourites(this.favoriteGamesIds, this.gameSorting === sortType.FAVOURITES_ADDED_ASC);
                    break;
                case sortType.NAME_ASC:
                case sortType.NAME_DESC:
                    sortFn = sortGamesByName(this.gameSorting === sortType.NAME_ASC);
                    break;
                case sortType.POPULAR:
                default:
                    sortFn = sortGamesByPopular;
                    break;
            }
            return this.games.data.filter(filterFn).sort(sortFn);
        },
        allGamesLoadError() {
            return this.games.error;
        },
        gamesCount() {
            return getObjectField(this.games, 'data.length', 0);
        },
        shouldShowFavouritesOnly() {
            return !!this.queryFavourites;
        },
        gamesLimit() {
            const oneRowLimit = getCasinoGamesLimitForGridPerRow(this.$mq.size);
            return oneRowLimit * GAMES_ROWS_PER_PAGE;
        },
    },
    mounted() {
        this.$scroll.scrollTo(0, 1);
        this.$store.dispatch(casinoAction.GET_CASINO_GAMES_NEW);
        if (this.$route.query[CasinoFilter.FAVOURITES] && !this.isAuthenticated) {
            this.goToCasinoLobby();
        }
    },
    created() {
        this.$gtm.query({ event: 'casino_lobby_page_view' });
    },
    beforeDestroy() {
        this.clearNotification();
    },
    methods: {
        ...mapMutations({
            clearNotification: generalMutation.CLEAR_NOTIFICATIONS,
            setGamesSortBy: casinoMutation.SET_GAMES_SORT_BY,
            setFavouriteGamesSortBy: casinoMutation.SET_FAVOURITE_GAMES_SORT_BY,
        }),

        handleToggleDropdown() {
            this.sortingIsOpen = !this.sortingIsOpen;
        },
        goToCasinoLobby() {
            this.$router.push({ name: routeName.CASINO });
        },
        setSorting(sortBy) {
            if (this.shouldShowFavouritesOnly) {
                this.setFavouriteGamesSortBy(sortBy);
            } else {
                this.setGamesSortBy(sortBy);
            }
        },
        errorButtonClick() {
            window.location.reload();
        },
    },
};
</script>

<style scoped lang="scss">
.title-container {
    display: flex;
    flex-direction: row;
    margin: 0 12px;

    h2 {
        flex: 1;
        margin: 0;
        padding: 0;
    }
}
</style>
