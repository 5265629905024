import { render, staticRenderFns } from "./LiveEvent.vue?vue&type=template&id=4cd7c0ae&scoped=true"
import script from "./LiveEvent.vue?vue&type=script&lang=js"
export * from "./LiveEvent.vue?vue&type=script&lang=js"
import style0 from "./LiveEvent.vue?vue&type=style&index=0&id=4cd7c0ae&prod&scoped=true&lang=scss"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "4cd7c0ae",
  null
  
)

export default component.exports