<template>
    <div v-if="isVideoEnabled && getYoutubeId && !isPresto" class="container">
        <iframe :id="getYoutubeId" class="video" frameborder="0" allowfullscreen :src="`https://www.youtube.com/embed/${getYoutubeId}`" />
    </div>
</template>

<script>
import { deviceType } from '@/modules/core';

export default {
    name: 'Video',
    props: ['data'],
    data() {
        return {
            isPresto: deviceType.isPresto(),
        };
    },
    computed: {
        getYoutubeId() {
            const youtubeLink = this.data?.content?.youtube;
            try {
                // supported formats:
                // from url - https://www.youtube.com/watch?v=-m97RWGw-VA&t=179s
                // from share - https://youtu.be/-m97RWGw-VA?feature=shared&t=180
                const link = youtubeLink?.split('/').pop();
                const youtubeUrl = new URL(youtubeLink);
                const newQueryParams = youtubeUrl.searchParams;

                let videoId = link.replace(/\?.+/, '');
                if (link.startsWith('watch')) {
                    // video from url
                    videoId = newQueryParams.get('v');
                    newQueryParams.delete('v');
                }
                const startTime = newQueryParams.get('t');
                if (startTime) {
                    newQueryParams.set('start', parseInt(startTime));
                    newQueryParams.delete('t');
                }
                const params = newQueryParams.size ? `?${newQueryParams.toString()}` : '';
                return `${videoId}${params}`;
            } catch (error) {
                console.error(error, { videoLink: youtubeLink });
                return '';
            }
        },
        isVideoEnabled() {
            return this.data?.content?.isVideoEnabled;
        },
    },
};
</script>

<style scoped lang="scss">
.container {
    position: relative;
    width: 100%;
    height: 0;
    min-width: 300px;
    max-width: 960px;
    padding-bottom: 56.25%; // fallback for old browsers
    padding-bottom: min(540px, 56.25%);
    margin: 0 auto;
}
.video {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
}
</style>
