<template>
    <div class="casino-game-brands" />
</template>

<script>
import { mapGetters } from 'vuex';
import { getter as coreGetter } from '@/modules/core/store/const';

export default {
    name: 'CasinoGameBrands',
    props: {
        data: Object,
    },
    computed: {
        ...mapGetters({
            isLoading: coreGetter.IS_LOADING,
        }),
    },
};
</script>

<style scoped lang="scss">
.casino-game-brands {
    margin-top: 16px;
    margin-bottom: 16px;

    padding: 0 12px 0 12px;
    display: grid;
    grid-auto-flow: column;
    --gap: 18px;
    grid-gap: calc(var(--gap) - 6px);
    overflow-y: hidden;
    overflow-x: scroll;
    scrollbar-width: none;
    user-select: none;
    scroll-snap-type: x mandatory;

    --columns: 2;
    @include mq-xxs {
        --columns: 3;
    }

    @include mq-xs {
        --columns: 4;
    }

    @include mq-sm {
        --columns: 4;
    }

    @include mq-md {
        --columns: 4;
    }

    grid-auto-columns: calc((100% - (var(--columns) - 1) * var(--gap)) / var(--columns));

    .casino-brands-item {
        scroll-margin: 12px;
        user-select: none;
        scroll-snap-align: center;
    }
}
</style>
