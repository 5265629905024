import Vue from 'vue';
import { cmsActions } from '@/js/utils/cms/cms-const';
import { modalNames } from '@/js/utils/cms/cms-modals';

const handler = (actionName) => {
    switch ((actionName || '').toLowerCase()) {
        case cmsActions.VERIFICATION_CLICK_TO_DIAL_CODE:
            Vue.$gtm.query({ event: 'verification_click_to_dial' });
            Vue.$modal.hide(modalNames.VERIFICATION_MODAL);
            break;
        default:
            break;
    }
};

export default handler;
