import store from '@/store/store';
import { endpoints as platformEndpoints } from '@/modules/platform';
import { action } from '@/modules/platform/store/const';
import { routeName } from '@/router/const-name';
import { routePath } from '@/router/const-path';

export default [
    {
        path: '/a/:affiliateId',
        redirect: (to) => {
            store.dispatch(action.SET_AFFILIATE_ID, {
                path: to.fullPath.replace('/a/', platformEndpoints.affiliates.setAffiliateId),
                id: to.params.affiliateId,
            });
            let { dest, ...queryParams } = to.query;
            // Hard fix for production to support broken link
            if (dest && dest.includes('?')) {
                const [updatedDest, ...params] = dest.split('?');
                dest = updatedDest;
                params.forEach((item) => {
                    if (!queryParams) {
                        queryParams = {};
                    }
                    const [key, value] = item.split('=');
                    queryParams[key] = value;
                });
            }
            const route = { query: queryParams };
            if (dest) {
                route.path = dest;
            } else {
                route.name = routeName.HOMEPAGE;
            }
            return route;
        },
    },
    {
        path: '/signup',
        redirect: { name: routeName.JOIN_NOW },
    },
    {
        path: '/jackpot',
        redirect: (to) => {
            const hash = to.hash && to.hash.substring(1);
            if (!hash) {
                return { name: routeName.JACKPOT_PAGE, hash: undefined };
            } else if (['active', 'results', 'rules'].includes(hash)) {
                return { name: routeName.JACKPOT_PAGE, params: { tab: hash }, hash: undefined };
            } else {
                return {
                    name: routeName.JACKPOT_PAGE,
                    params: {
                        tab: 'active',
                        jackpotId: hash.split('-')[0],
                    },
                    hash: undefined,
                };
            }
        },
    },
    {
        path: '/jackpot-ticket-(\\d+)',
        redirect: (to) => ({ name: routeName.JACKPOT_TICKET, params: { ticketId: to.params.pathMatch } }),
    },
    {
        path: '/setpw',
        redirect: { name: routeName.SET_PASSWORD },
    },
    {
        path: '/lostpw(-\\d+)?',
        redirect: (to) => {
            const pathMatch = to.params.pathMatch && to.params.pathMatch.substring(1);
            if (!pathMatch) {
                return { name: routeName.RESET_PASSWORD };
            } else {
                return { name: routeName.RESET_PASSWORD, params: { phoneNumber: pathMatch } };
            }
        },
    },
    {
        path: '/betslip-(\\d+)',
        redirect: (to) => ({ name: routeName.BETSLIP, params: { id: to.params.pathMatch }, query: undefined }),
    },
    {
        path: `${routePath.COUNTRY}/:id?`,
        redirect: (to) => ({ ...to, name: routeName.COUNTRY }),
    },
    {
        path: `${routePath.GROUP}/:id`,
        redirect: (to) => ({ ...to, name: routeName.GROUP }),
    },
    {
        path: routePath.UPCOMING,
        redirect: (to) => ({ ...to, name: routeName.UPCOMING }),
    },
    {
        path: routePath.POPULAR,
        redirect: (to) => ({ ...to, name: routeName.POPULAR }),
    },
    {
        path: routePath.LIVE,
        redirect: (to) => ({ ...to, name: routeName.LIVE }),
    },
    {
        path: routePath.LIVE_CATEGORY,
        redirect: (to) => ({ ...to, name: routeName.LIVE_CATEGORY }),
    },
    {
        path: routePath.BOOSTED,
        redirect: (to) => ({ ...to, name: routeName.BOOSTED_EVENTS }),
    },
    ...(window.isBridgeEnabled
        ? [
              {
                  // Redirect to home page in android app
                  path: '/app',
                  redirect: () => ({ name: routeName.HOMEPAGE }),
              },
          ]
        : []),
];
