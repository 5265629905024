<template>
    <div class="bet-details">
        <div v-if="isTaxEnabled && stake && !!stakeTaxAmount">
            {{ $t('ui.betslip.stakeAfterTax') }}:
            <b :data-test-id="`stakeAfterTax`" class="nowrap">{{ $numberFormat(stakeAfterTax) }}</b>
        </div>
        <div>
            {{ $t('ui.betslip.odds') }}:
            <b :data-test-id="`totalOdds`" class="nowrap">{{ isPriceShown ? $numberFormat(totalOdds, undefined, undefined, ' ') : '-' }}</b>
        </div>
        <template v-if="isMultiplierSchema && isMultiplierShown">
            <div>
                {{ $t('ui.betslip.oddsMultiplier') }}:
                <b :data-test-id="`oddsMultiplier`" class="nowrap">x{{ $numberFormat(multiplier) }}</b>
            </div>
            <div>
                <b>{{ $t('ui.betslip.totalOdds') }}:</b>
                <b :data-test-id="`totalOdds`" class="nowrap">{{ $numberFormat(multiplierTotalOdds) }}</b>
            </div>
        </template>

        <template v-if="isBonusSchema">
            <div v-if="stake && totalWinnings > 0">
                {{ $t('ui.betslip.potentialWinnings') }}:
                <Currency
                    v-if="isPriceShown"
                    class="nowrap bold"
                    :amount="totalWinnings"
                    :format="currencyFormat"
                    :data-test-id="`totalWinnings`"
                />
                <b v-else class="nowrap">-</b>
            </div>
            <div v-if="stake && totalWinBonus > 0">
                {{
                    $t(`ui.betslip.${countryCodeIs.KE ? 'winBoost' : 'winBonus'}`, {
                        currentBonus: Math.round(currentBonus * 100),
                    })
                }}:
                <Currency
                    v-if="isPriceShown"
                    class="nowrap bold"
                    :amount="totalWinBonus"
                    :format="currencyFormat"
                    :data-test-id="`totalWinBonus`"
                />
                <b v-else class="nowrap">-</b>
            </div>
        </template>

        <template v-if="isWinTaxEnabled && stake && totalWinnings && tax">
            <div v-if="taxableWinnings">
                <span>{{ $t('ui.betslip.taxableWinnings') }}:</span>
                <Currency
                    v-if="isPriceShown"
                    class="nowrap bold"
                    :amount="taxableWinnings"
                    :format="currencyFormat"
                    :data-test-id="`taxableWinnings`"
                />
                <b v-else class="nowrap">-</b>
            </div>
            <div v-if="isTaxOnWin || taxTier || !isWht" data-test-class="tax">
                <span v-if="isTaxOnWin">{{ $t('ui.betslip.taxOnWin') }}:</span>
                <span v-else-if="taxTier"> {{ $t('ui.betslip.Wht', { percentage: taxTier.rate * 100 }) }}: </span>
                <span v-else>{{ $t('ui.betslip.tax') }}:</span>
                &#32;
                <Currency v-if="isPriceShown" class="nowrap bold" :amount="tax" :format="currencyFormat" :data-test-id="`tax`">-</Currency>
                <b v-else class="nowrap">-</b>
            </div>
            <template v-else-if="isWht">
                <div v-if="pawaBonus" data-test-class="tax">
                    <span> {{ $t('ui.betslip.pawaBonus') }}:</span>
                    <Currency v-if="isPriceShown" class="nowrap bold" :amount="pawaBonus" :format="currencyFormat" :data-test-id="`tax`" />
                </div>
                <div data-test-class="tax">
                    <span>{{ $t('ui.betslip.Wht', { percentage: taxAmount * 100 }) }}:</span>
                    &#32;
                    <Currency v-if="isPriceShown" class="nowrap bold" :amount="tax" :format="currencyFormat" :data-test-id="`tax`">
                        -
                    </Currency>
                </div>
            </template>
            <div v-if="isWhtCompensated">
                {{ $t('ui.betslip.WhtBonus') }}:
                <Currency v-if="isPriceShown" class="nowrap bold" :amount="tax" :format="currencyFormat" :data-test-id="`WhtBonus`" />
                <b v-else class="nowrap">-</b>
            </div>
        </template>
        <div v-if="stake && totalWinnings > 0">
            {{ $t('ui.betslip.payout') }}:
            <Currency v-if="isPriceShown" class="nowrap bold" :amount="payout" :format="currencyFormat" :data-test-id="`payout`" />
            <b v-else class="nowrap">-</b>
        </div>
    </div>
</template>

<script>
import { mapGetters, mapState } from 'vuex';
import { helper } from '@/modules/core';
import { getter as betslipGetter } from '@/modules/sport/store/modules/betslip/const';
import { Currency } from '@/modules/core/components';

import { getter as platformGetter, getter as generalGetter } from '@/modules/platform/store/const';

const MINIMAL_COEFFICIENT = 1;

export default {
    name: 'BetDetails',
    components: { Currency },
    props: {
        stake: {
            type: String,
            require: true,
        },
        taxAmount: {
            type: Number,
            require: false,
        },
        currentBonus: Number,
        isPriceShown: Boolean,
        tax: Number,
        taxableWinnings: Number,
        taxTier: Object,
        totalWinnings: Number,
        totalWinBonus: Number,
        payout: Number,
        pawaBonus: Number,
        stakeAfterTax: Number,
        multiplier: Number,
        multiplierTotalOdds: Number,
        isWht: Boolean,
        isTaxEnabled: Boolean,
        isWinTaxEnabled: Boolean,
    },
    computed: {
        ...mapState({
            currencyFormat: (state) => state.platform.settings.currency.format,
        }),
        isTaxOnWin() {
            return this.settings.betting.real.whtDisplayType === 'TAX_ON_WIN';
        },
        isMultiplierShown() {
            if (this.multiplier <= MINIMAL_COEFFICIENT) {
                return false;
            }

            return this.totalOdds >= this.settings.bonus.minimumTotalOdds;
        },
        /**
         * isWhtCompensated method
         * @deprecated
         * in new component-data API
         * @returns {(function(): *)|*|boolean}
         */
        isWhtCompensated() {
            const { isWhtCompensated } = this.brandPreferences;
            return isWhtCompensated && isWhtCompensated !== 'NONE';
        },
        ...mapGetters({
            totalOdds: betslipGetter.GET_TOTAL_ODDS,
            settings: generalGetter.GET_SETTINGS,
            brandPreferences: generalGetter.GET_BRAND_PREFERENCE,
            isMultiplierSchema: generalGetter.IS_MULTIPLIER_SCHEMA,
            isBonusSchema: generalGetter.IS_BONUS_SCHEMA,
            stakeTaxAmount: generalGetter.GET_STAKE_TAX_AMOUNT,
            countryCodeIs: platformGetter.COUNTRY_CODE_IS,
        }),
    },
    methods: {
        $interpolate: helper.interpolate,
        $numberFormat: helper.numberFormat,
    },
};
</script>

<style lang="scss" scoped>
.bet-details {
    @extend %body-small-font-400;
    vertical-align: top;

    > div {
        @extend %body-normal-font-400;
        color: $grey-text;
        display: flex;
        justify-content: space-between;

        &:not(:last-child) {
            margin-bottom: 4px;
        }

        b {
            color: $main-text;
        }
    }
}
</style>
