<template>
    <div class="ordered-list">
        <ol>
            <li v-for="(listItem, index) in data.content" :key="index">
                <div class="ordered-list-item">
                    <Paragraph :data="listItem.content[0]" />
                </div>

                <DynamicComponent v-if="listItem.content[1]" :data="listItem.content[1]" path="content" />
            </li>
        </ol>
    </div>
</template>
<script>
import Paragraph from './Paragraph';
import { DynamicComponent } from '@/modules/platform/components';

export default {
    name: 'OrderedList',
    components: { Paragraph, DynamicComponent },
    props: ['data'],
};
</script>
<style lang="scss" scoped>
.ordered-list {
    margin: 0.5em 0;

    &-item {
        display: flex;
        margin-bottom: 0.2em;
    }

    &-item:before {
        content: counters(item, '.') '. ';
        display: list-item;
        padding-right: 0.4em;
    }

    &-item p {
        word-break: break-word;
    }

    li > & {
        margin: 0 0 0 1.5em;
    }

    ol {
        list-style-type: none;
        counter-reset: item;
        margin: 0;
        padding: 0;
    }

    ol > li {
        counter-increment: item;
        margin-bottom: 0.2em;
    }
}
</style>
