<template>
    <div class="survey">
        <h1 v-if="survey.title" class="title" @click="code = !code">{{ survey.title }}</h1>
        <div v-if="survey.description" class="title description label">{{ survey.description }}</div>
        <template v-if="!surveyCompleted">
            <div
                v-for="(question, questionIndex) in survey.questions"
                :key="`question-${question.id}-${questionIndex}`"
                class="question label"
            >
                <div class="option">{{ question.option }}</div>
                <div
                    v-for="(answer, answerIndex) in question.answers.split(',')"
                    :key="`answer-${question.id}-${answerIndex}`"
                    class="answer"
                >
                    <div @click="answerClick({ id: question.id, answer })">
                        <Radiobutton
                            :id="`answer-${questionIndex}-${answerIndex}`"
                            theme="light"
                            :checked="isSelected(question.id, answer)"
                        />
                        <div class="label">{{ answer }}</div>
                    </div>
                </div>
            </div>
            <input
                type="submit"
                :value="!isAuthenticated ? $t('ui.common.login') : $t('ui.common.submit')"
                :disabled="isCtaDisabled()"
                class="button button-primary button-full"
                @click="ctaClick()"
            />
        </template>
        <div v-else class="notify success">
            {{ survey.successMessage || $t('ui.common.survey.completed') }}
        </div>
    </div>
</template>

<script>
import { mapGetters } from 'vuex';

import { action, getter, mutation } from '@/modules/platform/store/const';
import { getter as authGetter } from '@/modules/platform/store/modules/auth/const';
import Radiobutton from '@/components/Radiobutton';
import { routeName } from '@/router/const-name';

export default {
    name: 'Survey',
    components: { Radiobutton },
    props: ['data'],
    data() {
        return {
            isRequesting: false,
            code: false,
        };
    },
    computed: {
        ...mapGetters({
            isAuthenticated: authGetter.IS_AUTHENTICATED,
            preference: getter.GET_PREFERENCE,
            selected: getter.GET_SURVEY,
        }),
        surveyCompleted() {
            const { saveAs } = this.survey.settings;
            return this.preference[saveAs];
        },
        survey() {
            return this.data.content;
        },
    },
    methods: {
        answerClick(value) {
            this.$store.commit(mutation.SET_SURVEY, value);
        },
        isSelected(id, answer) {
            return this.selected && this.selected.id === id && this.selected.answer === answer;
        },
        ctaClick() {
            if (this.isAuthenticated) {
                this.saveSurvey();
            } else {
                this.$router.push({ name: routeName.LOGIN, query: { returnPrevPath: '1' } });
            }
        },
        isCtaDisabled() {
            if (!this.isAuthenticated) return false;
            return !this.selected || this.isRequesting;
        },
        saveSurvey() {
            this.isRequesting = true;
            const storageType = {
                PREFERENCES: 'preferences',
                GA4: 'ga4',
                COOKIE: 'cookie',
            };
            const { type, saveAs } = this.survey.settings;
            switch (type) {
                case storageType.PREFERENCES:
                    const { answer } = this.selected;
                    const value = answer.toLowerCase().split(' ').join('-');
                    const payload = { key: saveAs, value };
                    this.$store
                        .dispatch(action.PUT_DERIVED_DATA, payload)
                        .then(() => {
                            this.$store.commit(mutation.UPDATE_PREFERENCE, { [saveAs]: value });
                        })
                        .catch(() => {
                            this.isRequesting = false;
                        });
                    break;
                default:
                    break;
            }
        },
    },
};
</script>

<style lang="scss" scoped>
.survey {
    padding: 20px;
    border: 1px solid #e6e7e2;
    display: flex;
    flex-direction: column;

    .label {
        @extend %body-normal-font-400;
        display: inline-block;
        margin: 5px 0 5px;
        color: $grey-text;
    }

    .description {
        margin-bottom: 16px;
    }

    .option {
        padding-bottom: 10px;
    }
    .answer {
        > div {
            display: flex;
            align-items: center;
        }

        .label {
            margin: 8px 0 8px;
        }
    }

    .button {
        margin-top: 10px;
    }
}
</style>
