import { render, staticRenderFns } from "./OnSiteMessages.vue?vue&type=template&id=44bcaf93&scoped=true"
import script from "./OnSiteMessages.vue?vue&type=script&lang=js"
export * from "./OnSiteMessages.vue?vue&type=script&lang=js"
import style0 from "./OnSiteMessages.vue?vue&type=style&index=0&id=44bcaf93&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "44bcaf93",
  null
  
)

export default component.exports