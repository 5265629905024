<template>
    <transition name="slide">
        <div v-if="show" class="site-messages">
            <OnSiteMessage :message="currentMessage" @onCloseClick="onActionClick" @onCtaClick="onActionClick" />
        </div>
    </transition>
</template>
<script>
import { mapState } from 'vuex';
import { MESSAGE_STATUS } from '@/modules/platform';
import { action as messagingAction } from '@/modules/platform/store/modules/messaging/const';
import { helper } from '@/modules/core';

import OnSiteMessage from './OnSiteMessage.vue';

export default {
    name: 'OnSiteMessages',
    components: { OnSiteMessage },
    data() {
        return {
            show: false,
        };
    },
    computed: {
        ...mapState({
            onsiteMessages: (state) => state.platform.messaging.onsiteMessages,
        }),
        currentMessage() {
            if (!this.onsiteMessages?.length) return null;
            const newMessages = this.onsiteMessages.filter(
                (message) =>
                    !message.expired &&
                    message.messageStatus !== MESSAGE_STATUS.clicked &&
                    this.checkRoutePath(message.additionalView?.path)
            );
            return newMessages[0];
        },
    },
    watch: {
        currentMessage(newVal, oldVal) {
            if (newVal?.id === oldVal?.id) return;
            this.show = false;

            setTimeout(() => {
                this.show = true;
            }, 500);
        },
    },
    methods: {
        onActionClick(id) {
            this.show = false;
            this.markAsClicked(id);
        },
        markAsClicked(messageId) {
            this.$store.dispatch(messagingAction.MARK_AS_CLICKED, messageId);
        },
        checkRoutePath(messagePath) {
            if (!messagePath || !this.$route.name) return false;
            return helper.checkPathForMatchingCurrentRoute(this.$route.path, messagePath);
        },
    },
};
</script>

<style lang="scss" scoped>
.slide-enter-active {
    -moz-transition-duration: 1.5s;
    -webkit-transition-duration: 1.5s;
    -o-transition-duration: 1.5s;
    transition-duration: 1.5s;
    -moz-transition-timing-function: ease-in;
    -webkit-transition-timing-function: ease-in;
    -o-transition-timing-function: ease-in;
    transition-timing-function: ease-in;
}

.slide-enter-to {
    max-height: 100%;
    overflow: hidden;
}

.slide-enter {
    overflow: hidden;
    max-height: 0;
}
</style>
