<template>
    <div class="countdown" :class="[`countdown-${theme}`, { 'countdown-finish': !countdown }]">
        <div v-if="html" class="label" v-html="html" />
        <div v-if="start" class="refresh" :class="{ start: countdown, end: !countdown }">
            <div v-show="isLoadingQuery || !countdown" class="number countdown-live-icon">
                <SvgIcon icon-id="icon-live" class="icon-status livenow" />
            </div>
            <div v-show="countdown && !isLoadingQuery" class="number">{{ countdown }}</div>
            <svg
                class="countdown-circle-icon"
                :width="Math.floor(getCircleSize.svg / sizeOfIcon)"
                :height="Math.floor(getCircleSize.svg / sizeOfIcon)"
            >
                <circle
                    :style="getAnimationStyle"
                    r="10"
                    :cx="Math.floor(getCircleSize.circle / sizeOfIcon)"
                    :cy="Math.floor(getCircleSize.circle / sizeOfIcon)"
                />
            </svg>
        </div>
        <slot />
    </div>
</template>
<script>
import { helper } from '@/modules/core';

export default {
    name: 'Counter',
    props: {
        start: {
            type: Date,
        },
        text: {
            type: String,
            default: '',
        },
        html: {
            type: String,
            default: '',
        },
        refreshRate: {
            type: Number,
            default: 5,
        },
        strokeWidth: {
            type: String,
            default: '4',
        },
        sizeOfIcon: {
            type: Number,
            default: 1,
        },
        theme: {
            type: String,
            default: 'circle', // number
        },
    },
    data() {
        return {
            isLoadingQuery: false,
            countdown: null,
            interval: null,
        };
    },
    computed: {
        getAnimationStyle() {
            return this.countdown ? `animation-duration: ${this.refreshRate}s; stroke-width: ${this.strokeWidth}` : '';
        },
        getCircleSize() {
            switch (this.theme) {
                case 'number':
                    return { svg: 20, circle: 10 };
                case 'live-event':
                    return { svg: 20, circle: 10 };
                default:
                    return { svg: 40, circle: 20 };
            }
        },
    },
    watch: {
        start: {
            immediate: true,
            handler(newValue, oldValue) {
                if (newValue !== oldValue) {
                    this.setDecrementTimer(this.refreshRate);
                    this.isLoadingQuery = false;
                }
            },
        },
    },
    beforeDestroy() {
        clearInterval(this.interval);
    },
    methods: {
        $isEmpty: helper.isEmpty,
        setDecrementTimer(refreshRate) {
            clearInterval(this.interval);
            this.countdown = refreshRate;
            this.interval = setInterval(
                function () {
                    this.countdown--;
                    if (this.countdown === 0) {
                        this.isLoadingQuery = true;
                        clearInterval(this.interval);
                        this.$emit('update');
                    }
                }.bind(this),
                1000
            );
        },
    },
};
</script>

<style scoped lang="scss">
@keyframes fillup {
    to {
        stroke-dasharray: 63 63;
    }
}

@keyframes done {
    to {
        stroke-dasharray: 60 20;
    }
}

.countdown {
    .label {
        color: $countdown-label-color;
        font-weight: normal;
        font-size: 10px;
        text-transform: uppercase;
        text-align: center;
        line-height: 11px;
    }

    .refresh {
        position: relative;
        display: flex;
        align-items: center;
        justify-content: center;
        svg {
            transform: rotate(-90deg);
            background: transparent;
            border-radius: $border-radius-circle;

            circle {
                fill: transparent;
                stroke: transparent;
                stroke-width: 4;
                animation-play-state: running;
            }

            &.livenow {
                width: 12px;
                height: 12px;
                fill: $countdown-refresh-icon-color;
            }
        }

        &.end svg circle {
            fill: transparent;
            stroke: $countdown-refresh-circle-color;
            stroke-dasharray: 0 6;
            animation: done 1s linear reverse 1;
            // animation-play-state: running
        }

        &.start svg circle {
            fill: transparent;
            stroke: $countdown-refresh-circle-color;
            stroke-dasharray: 0 63;
            animation: fillup 5s linear 1;
            // animation-play-state: running;
        }

        .number {
            position: absolute;
            @include only_ie {
                position: relative;
                width: 100%;
                text-align: center;
            }
            color: $countdown-number-color;
            font-weight: bold;
            font-size: 10px;
            z-index: 1;

            svg {
                transform: none;
                width: 12px;
                height: 12px;
            }
        }
    }

    &-number {
        &.countdown-finish {
            .countdown-live-icon,
            .countdown-circle-icon {
                visibility: visible;
            }
            .refresh {
                &.end svg circle {
                    animation: fillup 1s linear infinite;
                }
            }
        }
        .refresh {
            .number {
                font-size: 14px;
            }
        }
        .countdown-live-icon,
        .countdown-circle-icon {
            visibility: hidden;
        }
    }
}
</style>
