<template>
    <a :href="data.link" :target="target" :class="['underline', className]" @click="track()">
        {{ data.text }}
    </a>
</template>
<script>
import { mapGetters } from 'vuex';
import { getter as platformGetter } from '@/modules/platform/store/const';
import { linkTarget } from '@/components/content/content-const';
export default {
    name: 'Link',
    props: ['data'],
    data() {
        return {
            className: this.data.class || this.data.cssClass,
            target: linkTarget[this.data.subset],
        };
    },
    computed: mapGetters({
        country: platformGetter.GET_COUNTRY,
    }),
    methods: {
        track() {
            // implement this properly with Strapi
            switch (this.data.track) {
                case 'file_download':
                    this.$gtm.query({
                        event: 'file_download',
                        file_name: this.data.link.split('/').pop(),
                        link_domain: this.country,
                    });
                    break;
                case 'contact':
                    this.$gtm.query({
                        event: 'contact',
                        reason: 'email',
                    });
                    break;
            }
        },
    },
};
</script>

<style scoped lang="scss">
a {
    display: block;
}
</style>
