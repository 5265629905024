<template>
    <div class="menu table" :class="{ small: $mq.isXSSmall }">
        <div class="row-cell align-middle">
            <slot />
        </div>
        <div class="top-menu-wrapper row-cell align-middle">
            <div
                v-click-outside:[true]="handleMenuClose"
                class="menu-button button"
                :class="{ 'menu-button-active': showDropDown }"
                @click="toggleMenu"
            >
                <span class="top-menu-icon-wrapper">
                    <SvgIcon class="icon-top-menu" icon-id="icon-top-menu" />
                    <SvgIcon v-show="newMessagesNumber && isAuthenticated" class="icon-messages-menu" icon-id="icon-messages-menu" />
                </span>
                <span>{{ $t('ui.topMenuText') }}</span>
            </div>
            <TopMenuDropdown v-if="!$mq.isVerySmall" />
        </div>

        <a v-if="$mq.isXMedium" id="Bet-Slip-Topbar-Icon" class="bp-icon betslip row-cell align-middle" @click.prevent="toggleBetslip()">
            <span id="Betslip-Selected-Count" class="counter table" :class="{ inactive: isPlaced || !betCount }">
                <div>
                    <SvgIcon :icon-id="sportIcon" class="icon-sport sport-icon row-cell" />
                </div>
                <span class="bet-count row-cell align-middle">{{ isPlaced ? 0 : betCount }}</span>
            </span>
        </a>
    </div>
</template>

<script>
import { mapState, mapGetters } from 'vuex';
import { action } from '@/store/const';
import { sportType } from '@/modules/sport';
import { getter as sportGetter } from '@/modules/sport/store/const';
import { getter as betslipGetter } from '@/modules/sport/store/modules/betslip/const';
import { getter as authGetter } from '@/modules/platform/store/modules/auth/const';
import { getter as messagingGetter } from '@/modules/platform/store/modules/messaging/const';

const TopMenuDropdown = () => import('./TopMenuDropdown.vue');

export default {
    name: 'TopMenu',
    components: { TopMenuDropdown },
    computed: {
        ...mapState({
            showDropDown: (state) => state.ui.topMenuOpen,
        }),
        ...mapGetters({
            isAuthenticated: authGetter.IS_AUTHENTICATED,
            newMessagesNumber: messagingGetter.GET_NOTSEEN,
            sportType: sportGetter.GET_SPORT_TYPE,
            isVirtual: sportGetter.IS_VIRTUAL_SPORT,
            selectedBetslip: betslipGetter.GET_SELECTED_BETSLIP,
        }),
        betCount() {
            const { selectedBetsIds } = this.selectedBetslip;
            return selectedBetsIds.length;
        },
        isPlaced() {
            const { status } = this.selectedBetslip;
            return status.Placed;
        },
        sportIcon() {
            return this.sportType === sportType.SPORT ? 'icon-betslip' : `icon-${this.sportType}`;
        },
    },
    methods: {
        toggleMenu() {
            this.$store.commit('closeMobileSearch');
            this.$store.dispatch(action.SET_SIDEBAR_STATE, false);
            this.$store.commit('setTopMenuState', !this.showDropDown);
            this.$gtm.query({
                event: 'top_nav',
                click_text: 'menu',
            });
        },
        toggleBetslip() {
            this.$store.commit('closeMobileSearch');
            this.$store.dispatch(action.TOGGLE_BETSLIP_STATE);
            this.$store.dispatch(action.SET_SIDEBAR_STATE, false);
            this.$gtm.query({ event: 'toggle_betslip_menu' });
        },
        handleMenuClose() {
            if (this.showDropDown) {
                this.$store.commit('setTopMenuState', false);
            }
        },
    },
};
</script>

<style lang="scss" scoped>
.menu {
    padding-right: 8px;

    &-button {
        position: relative;
        color: $white-bg;
        font-size: 11px;
        line-height: 12px;
        font-weight: bold;
        padding: 14px 16px;
        text-transform: uppercase;
        display: inline-block;
        cursor: pointer;

        @include maxwp {
            padding: 14px 10px;
        }

        @include maxmain {
            padding: 14px 5px;
        }

        .small & {
            position: inherit;
        }

        .top-menu-icon-wrapper {
            position: relative;
            margin-right: 8px;
        }

        .icon-top-menu {
            width: 16px;
            height: 16px;
            fill: #f4f5f0;
        }

        .icon-messages-menu {
            position: absolute;
            top: -5px;
            left: 8px;
            width: 15px;
            height: 12px;
            fill: $white-bg;
            padding: 1px;
            background: $badge-new-messages-background;
        }

        &-active {
            color: $top-menu-button-active-color;

            .icon-top-menu {
                fill: $top-menu-button-active-color;
            }

            .icon-messages-menu {
                display: none;
            }
        }
    }

    .betslip {
        @include oldschool {
            margin-left: 4px;
            margin-right: 4px;
        }

        .counter {
            background: $top-menu-counter-color;
            color: $top-menu-counter-text-color;
            font-size: 14px;
            line-height: 15px;
            padding: 5px 7px;
            text-align: center;
            border-radius: $border-radius;
            margin: 5px 0;

            &.inactive {
                background: $top-menu-counter-color-inactive;

                svg.svg-icon {
                    fill: $top-menu-icon-color;
                }
            }

            svg.svg-icon {
                fill: $top-menu-icon-color;
                width: 18px;
                height: 18px;

                &.icon-sport {
                    width: 16px;
                    height: 16px;
                }
            }

            .bet-count {
                padding-left: 5px;
            }
        }
    }
}
</style>
