<template>
    <div class="my-bets-page bets-header">
        <Headline class="headline" :title="$t('ui.myBets.title')" :is-small-font="true" />
        <MyBets />
    </div>
</template>

<script>
import PageMixin from '@/components/Pages/Page.mixin';
import SEOMixin from '@/components/Pages/SEO.mixin';
import MyBets from '../Fragments/MyBets.vue';

export default {
    name: 'MyBetsView',
    components: { MyBets },
    mixins: [PageMixin, SEOMixin],
};
</script>

<style lang="scss" scoped>
.my-bets-page {
    background-color: initial;
}

.bets-header {
    margin: 0 auto;
    padding-bottom: 10px;
    width: 100%;
}

.headline {
    background: $headline-background;
    border-bottom: none;
    padding: 9px 12px;
}
</style>
