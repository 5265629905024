<template>
    <div class="page-not-found">
        <ErrorPage
            :img-src="imgSrc"
            :title="$t('ui.errorPage.pageNotFound.title')"
            :section-list="[$t('ui.errorPage.pageNotFound.description')]"
        >
            <template slot="footer">
                <router-link :to="{ path: '/upcoming' }" class="button button-primary error-button">
                    {{ $t('ui.eventPage.eventEnded.browseEvents') }}
                </router-link>
                <div class="links-wrapper">
                    <router-link :to="{ path: '/' }" class="error-link link">{{ $t('menus.home') }}</router-link>
                    <router-link :to="{ path: '/help' }" class="error-link link">{{ $t('menus.help') }}</router-link>
                    <router-link v-if="!isAuthenticated" :to="{ path: '/why-join' }" class="error-link link">
                        {{ $t('menus.whyJoin') }}
                    </router-link>
                </div>
            </template>
        </ErrorPage>
    </div>
</template>

<script>
import { mapGetters } from 'vuex';
import { getter as authGetter } from '@/modules/platform/store/modules/auth/const';
import { deviceType } from '@/modules/core';
import ErrorPage from '@/components/Pages/ErrorPage';
import { ERROR_IMAGES } from '@/components/content/content-const';

export default {
    name: 'PageNotFound',
    components: { ErrorPage },
    computed: {
        ...mapGetters({
            isAuthenticated: authGetter.IS_AUTHENTICATED,
        }),
        imgSrc() {
            return deviceType.isPresto() ? '' : ERROR_IMAGES.error404;
        },
    },
};
</script>

<style scoped lang="scss">
.page-not-found {
    ::v-deep .links-wrapper {
        text-align: center;
    }
    ::v-deep .link {
        text-decoration: underline;
        display: inline-block;
        vertical-align: top;
    }
}
</style>
