<template>
    <div>
        <h2>{{ $t('ui.payment.deposit.title') }}</h2>
        <!-- BP-16141 Move to DepositList with bool prop -->
        <DepositList :title-on-off="true" :text="$t('ui.payment.deposit.selectDepositMethod')" />
    </div>
</template>

<script>
import { DepositList } from '@/modules/payment/components';

export default {
    name: 'Depositoptions',
    components: { DepositList },
};
</script>
