<template>
    <div class="dynamic-slot">
        <div v-if="inProgress" class="dynamic-slot-spinner">
            <Spinner class="align-center transparent" :visible="inProgress" />
        </div>
        <template v-if="data && data.content">
            <DynamicComponent v-for="(item, index) in data.content" :key="'content' + index" :data="item" path="content" />
        </template>
    </div>
</template>

<script>
import { strapiPageSlots } from '@/modules/platform';
import { action as platformAction, getter as platformGetter } from '@/modules/platform/store/const';
import { getter as coreGetter } from '@/modules/core/store/const';

export default {
    name: 'DynamicSlot',
    props: {
        component: {
            type: String,
            required: true,
            validator(value) {
                return Object.values(strapiPageSlots).includes(value);
            },
        },
    },
    computed: {
        data() {
            return this.$store.getters[platformGetter.GET_CONTENT_SLOTS][this.component];
        },
        inProgress() {
            return this.$store.getters[coreGetter.IS_LOADING](platformAction.GET_CONTENT);
        },
    },
};
</script>

<style scoped lang="scss">
.dynamic-slot {
    &-spinner {
        position: relative;
        min-height: 40px;
    }
}
</style>
