<template>
    <div v-if="eventsToShow && eventsToShow.length" :class="['events', className]">
        <GameCategory
            v-if="events"
            :model="eventsToShow"
            :more-events="moreEvents"
            :enable-show-more="enableShowMore"
            :type="categoryType"
            :headline="headline"
            :info-category-id="infoId"
            :is-live="isLive"
            :is-boosted="isBoosted"
            @onCategoryChange="setSportCategory($event)"
        />
    </div>
</template>

<script>
import { mapGetters, mapState } from 'vuex';
import { action as sportAction, getter as sportGetter } from '@/modules/sport/store/const';
import { getter } from '@/modules/core/store/const';
import { mutation } from '@/store/const';

import GameCategory from '../GameCategory.vue';

const pageType = {
    LIVE_PAGE: 'live-popular',
    FRONT_PAGE: 'live',
    PAWA_BOOST: 'boosted',
};

export default {
    name: 'EventsView',
    components: { GameCategory },
    props: {
        data: Object,
        params: Object,
    },
    data: () => ({
        showPopular: false,
    }),
    computed: {
        ...mapState({
            eventsByCategoryId: (state) => state.sport.sports.eventsByCategoryId || {},
            liveEvents: (state) => state.sport.sports.liveEvents || {},
            livePopular: (state) => state.sport.sports.livePopular || [],
            boostedEvents: (state) => state.sport.sports.boosted || [],
        }),
        ...mapGetters({
            isLoading: getter.IS_LOADING,
            eventsCount: sportGetter.EVENTS_COUNT_PER_TYPE,
        }),
        totalCount() {
            if (this.isLive) {
                return this.eventsCount.liveEventsCount;
            }
            if (this.isBoosted) {
                return this.eventsCount.boostedEventsCount[this.categoryId] || 0;
            }
            return this.eventsCount.upcomingEventsCount[this.categoryId] || 0;
        },
        sortedLivePopular() {
            return this.livePopular?.filter((item) => Number(item?.category.id) === this.categoryId);
        },
        moreEvents() {
            let count;
            if (this.isLivePage) {
                count = this.categoryId
                    ? this.sortedLivePopular?.length + this.liveEvents[this.categoryId]?.length - this.eventsToShow.length
                    : null;
            } else {
                count = this.totalCount && this.totalCount - this.eventsToShow.length;
            }

            return count > 0 && count;
        },
        eventsToShow() {
            const eventsToShow = this.itemsToShow && [...(this.events || [])].slice(0, this.itemsToShow);
            return eventsToShow || this.events;
        },
        events() {
            if (this.isBoosted) {
                return this.boostedEvents;
            }

            if (this.isPopular) {
                this.setMessage();
                return this.livePopular;
            }

            let eventsByCategoryId = this.isLive ? this.liveEvents[this.categoryId] : this.eventsByCategoryId[this.categoryId];

            this.setMessage(eventsByCategoryId);

            if (eventsByCategoryId && this.params && this.params.sort) {
                return eventsByCategoryId.filter(this.params.sort);
            }

            return eventsByCategoryId;
        },
        categoryType() {
            return (
                (this.isBoosted && pageType.PAWA_BOOST) ||
                (this.isPopular && pageType.LIVE_PAGE) ||
                (!this.isLivePage && pageType[this.data.algorithm]) ||
                this.categoryId
            );
        },
        isBoosted() {
            return this.data.boosted === 'true';
        },
        isLive() {
            return this.data.live === 'true';
        },
        isPopular() {
            return this.data.popular === 'true';
        },
        isLivePage() {
            return this.data.algorithm === 'LIVE_PAGE';
        },
        enableShowMore() {
            const { showMore } = this.data;
            return !!showMore || !this.isLivePage;
        },
        isLiveCategoryPage() {
            return this.isLivePage && this.$route.params.category;
        },
        categoryId() {
            const [categoryId] = this.data.categoryId?.split(',') || [];
            return Number(categoryId);
        },
        itemsToShow() {
            return Number(this.data.itemsToShow);
        },
        className() {
            return this.data.class;
        },
        infoId() {
            return this.isBoosted && 'PAWABOOST_RULES';
        },
        headline() {
            return this.data.headline || {};
        },
    },
    created() {
        this.$emit('action', { action: sportAction.GET_WIDGET_BASED_EVENTS, useSportParams: true });
    },
    methods: {
        setSportCategory(id) {
            this.$store.commit(mutation.SET_CURRENT_CATEGORY, id);
        },
        setMessage(events = []) {
            if (!this.isLivePage || this.isLoading(sportAction.GET_WIDGET_BASED_EVENTS)) {
                return;
            }
            if (this.isLiveCategoryPage ? !events.length : !this.livePopular?.length) {
                this.$emit('showMessage', this.$t('ui.common.error.noActiveEvents'));
            }
        },
    },
};
</script>
<style scoped lang="scss">
.events {
    position: relative;
}
</style>
