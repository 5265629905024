<template>
    <div>
        <form v-if="isMounted" class="login-form" @submit.prevent>
            <PhoneNumber
                :form-name="formNameId"
                :v="$v.form.username"
                :value="form.username"
                v-bind="{
                    ...(phoneNumberValidation && {
                        placeholder: phoneNumberValidation.placeholder,
                        errorMessages: { phoneNumberFormat: $t(phoneNumberValidation.errorMessageKey) },
                    }),
                }"
                @value="form.username = $event"
            />
            <Password
                :form-name="formNameId"
                :v="$v.form.password"
                :value="form.password"
                name="password-input"
                autocomplete="new-password"
                @value="form.password = $event"
            />
            <renderer v-if="error" class="login-error" :input="error" />
            <Checkbox v-if="!isPresto && isSmartPhone" id="keepLoggedIn" :checked="keepLoggedIn" @onCheck="setKeepLoggedIn">
                <span>{{ $t('ui.common.user.keepLoggedIn') }}</span>
            </Checkbox>
            <input
                type="submit"
                :aria-placeholder="$t('ui.common.logIn')"
                data-test-id="logInButton"
                class="button button-submit button-full"
                :value="$t('ui.common.logIn')"
                :disabled="isSubmitDisabled"
                @click="login()"
            />
            <router-link
                :to="{ name: 'Reset Password', params: { phoneNumber: form.username || null } }"
                class="info underline text-mid bold"
            >
                {{ $t(`ui.common.${pinOrPassword}.forgotPassword`) }}
            </router-link>
            <span class="join-now-text">
                {{ $t('ui.common.user.joinNowText') }}
                <router-link :to="{ name: 'JoinNow' }" class="bold underline" @click.native="trackLinkClick">
                    {{ $t('ui.common.user.joinNow') }}
                </router-link>
            </span>
        </form>
    </div>
</template>

<script>
import { mapState, mapGetters, mapMutations } from 'vuex';
import { required, minLength, numeric } from 'vuelidate/lib/validators';

import { helper, android, deviceType } from '@/modules/core';
import { getter as coreGetter } from '@/modules/core/store/const';

import { getter as platformGetter } from '@/modules/platform/store/const';
import { action as authAction, mutation as authMutation } from '@/modules/platform/store/modules/auth/const';

import { getter } from '@/store/modules/translations/const';

import Password from '@/components/Password.vue';
import Checkbox from '@/components/Checkbox.vue';
import PhoneNumber from '@/components/PhoneNumber.vue';

export default {
    name: 'LoginForm',
    components: { Password, PhoneNumber, Checkbox },
    data() {
        return {
            isMounted: false,
            form: {
                username: null,
                password: null,
            },
            formNameId: 'login-form',
            keepLoggedIn: false,
            isPresto: deviceType.isPresto(),
            isSmartPhone: deviceType.isSmartPhone(),
        };
    },
    validations() {
        const isPin = this.brandPreference.pin;
        return {
            form: {
                username: {
                    required,
                    ...(this.phoneNumberValidation && { phoneNumberFormat: this.phoneNumberValidation.validator }),
                },
                password: {
                    required,
                    minLength: minLength(4),
                    ...(isPin && { numeric }),
                },
            },
        };
    },
    computed: {
        ...mapState({
            authError: (state) => state.platform.auth.error,
            phonePrefix: (state) => helper.getObjectField(state.platform.settings, 'brandIdentity.phoneCountryCode'),
            defaultKeepMeLoggedIn: (state) => !!state.platform.config.defaultKeepMeLoggedIn,
            storedPhoneNumber: (state) => state.platform.auth.phoneNumber,
        }),
        ...mapGetters({
            pinOrPassword: getter.PIN_OR_PASSWORD,
            country: platformGetter.GET_COUNTRY,
            brandPreference: platformGetter.GET_BRAND_PREFERENCE,
            phoneNumberValidation: platformGetter.GET_PHONE_NUMBER_VALIDATION,
        }),
        inProgress() {
            return this.$store.getters[coreGetter.IS_LOADING](authAction.LOGIN);
        },
        isSubmitDisabled() {
            return (!this.isPresto && this.$v.$invalid) || this.inProgress;
        },
        error() {
            return this.authError;
        },
    },
    mounted() {
        this.isMounted = true;
        if (this.phoneNumberValidation && this.form.username) this.$v.form.username.$touch();
    },
    created() {
        this.$store.dispatch(authAction.RESET_ERROR);
        const phoneNumber = android.state.loginPhone || this.storedPhoneNumber;
        this.form.username = helper.removeCountryCode(phoneNumber, this.phonePrefix);
        this.setKeepLoggedIn(this.defaultKeepMeLoggedIn);
    },
    beforeDestroy() {
        this.$store.dispatch(authAction.RESET_ERROR);
        this.$store.commit(authMutation.SET_PHONE_NUMBER, {
            phoneNumber: this.form.username,
            phonePrefix: this.phonePrefix,
        });
    },
    methods: {
        ...mapMutations({
            setError: authMutation.SET_ERROR,
        }),
        trackLinkClick() {
            this.$gtm.query({
                event: 'join_now_click',
                click_element: 'link',
            });
        },
        setKeepLoggedIn(value) {
            this.keepLoggedIn = value;
        },
        login() {
            if (this.isPresto && this.$v.$invalid) {
                this.$v.$touch();
                return;
            }
            const credentials = {
                username: this.form.username,
                password: this.form.password,
                country: this.country,
                rememberMe: !this.isPresto && this.isSmartPhone && this.keepLoggedIn,
            };
            this.$store
                .dispatch(authAction.LOGIN, credentials)
                .then(() => {
                    this.$emit('success');
                })
                .finally(() => {
                    this.form.password = null;
                    this.$v.form.password.$reset();
                });
        },
    },
};
</script>

<style lang="scss" scoped>
.login {
    &-form {
        display: flex;
        flex-flow: column nowrap;
        .join-now-text {
            @extend %small-details-font-400;
            text-align: center;
        }
        .info {
            margin: 16px 0 8px 0;
        }
    }
    &-error {
        color: $login-error-color;
        background-color: $login-error-background-color;
        border: $login-error-border-width solid $login-error-border-color;
        padding: 12px;
        font-size: 14px;
        margin-bottom: 16px;
        border-radius: $login-error-border-radius;
    }
}
</style>
