import { getLocalTypes, unique } from '@/store/utils'; // to core utils BP-16141
import { action, mutation, getter } from '@/modules/core/store/const';

const state = {
    loader: [],
};

const _getter = getLocalTypes(getter);

const getters = {
    [_getter.IS_LOADING]: (state) =>
        function (loaderName) {
            return state.loader.indexOf(loaderName) > -1;
        },
    [_getter.ARE_LOADING]: (state) => (loaderNames) => {
        return loaderNames.some((elem) => state.loader.includes(elem));
    },
    [_getter.ANY_LOADING]: (state) => state.loader.length > 0,
};

const _mutation = getLocalTypes(mutation);

const mutations = {
    [_mutation.START_LOAD](state, value) {
        if (!value) return;
        state.loader.push(value);
        state.loader = unique(state.loader);
    },
    [_mutation.END_LOAD](state, value) {
        if (!value) return;
        state.loader = unique(state.loader).filter((elem) => elem !== value);
    },
};

const _action = getLocalTypes(action);

const actions = {
    [_action.WATCHER](context, payload) {
        const [getter, promise, options = {}, callbackFn = () => {}] = payload;
        return new Promise((resolve) => {
            if (getter()) {
                resolve(promise({ ...getter() }));
            } else {
                const unwatch = this.watch(
                    getter,
                    (getterPayload) =>
                        resolve(
                            promise({ ...getterPayload })
                                .finally(() => unwatch())
                                .finally(() => callbackFn({ ...getterPayload }))
                        ),
                    options
                );
            }
        });
    },
    [_action.LOADER](context, payload) {
        const [getter, promise] = payload;
        return new Promise((resolve, reject) => {
            if (getter && Object.keys(getter).length) {
                resolve(getter);
            } else {
                promise(resolve, reject);
            }
        });
    },
};

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations,
};
