<template>
    <div class="value-proposition">
        <router-link :to="{ path }" class="link">
            <PromoBanner
                :title="$t('ui.winBonus.doubleYourWin')"
                :sub-title="$t('ui.winBonus.bestWinBonus', { countryName })"
                :items="items"
            />
        </router-link>
    </div>
</template>
<script>
import { getter as platformGetter } from '@/modules/platform/store/const';
import { mapGetters } from 'vuex';

const PromoBanner = () => import('@/components/Fragments/PromoBanner');

export default {
    name: 'Winbonus',
    components: { PromoBanner },
    props: ['data'],
    data() {
        return {
            path: '/win-bonus',
            icons: [
                { icon: 'badge-50-pc', key: 'badge50pc' },
                { icon: 'badge-100-pc', key: 'badge100pc' },
                { icon: 'badge-250-pc', key: 'badge250pc' },
            ],
        };
    },
    computed: {
        ...mapGetters({
            brandPreference: platformGetter.GET_BRAND_PREFERENCE,
        }),
        countryName() {
            return this.brandPreference.countryName;
        },
        items() {
            return this.icons.map((item) => ({ ...item, text: this.$t(`ui.winBonus.${item.key}`) }));
        },
    },
};
</script>

<style scoped lang="scss">
.value-proposition {
    .link:hover:not(.button) {
        color: inherit;
    }
}
</style>
